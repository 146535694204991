import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/core/reservation.service';

@Component({
  selector: 'app-contract-license',
  templateUrl: './contract-license.component.html',
  styleUrls: ['./contract-license.component.scss'],
})
export class ContractLicenseComponent implements OnInit {
  @Input() customer: User;

  constructor() {}

  ngOnInit(): void {}
}
