<div id="irt-home-services-root-layout">
  <h3 class="irt-no-margin">I NOSTRI SERVIZI</h3>

  <div
    id="irt-home-services-list-layout"
    class="irt-double-margin-top irt-full-width">
    <div
      id="irt-home-services-list-item-layout"
      class="irt-border-radius"
      *ngFor="let service of services">
      <h2 class="irt-centered-text irt-no-margin">{{ service.title }}</h2>
      <p
        id="irt-home-services-list-item-description"
        class="irt-centered-text irt-primary-medium-color">
        {{ service.description }}
      </p>
      <button
        class="irt-secondary-button irt-full-width"
        mat-button
        (click)="onInfoClick()">
        <h3 class="irt-no-margin irt-ellipsized-text">Maggiori informazioni</h3>
      </button>
    </div>
  </div>

  <div id="irt-home-services-actions-layout">
    <button
      class="irt-main-button irt-full-width"
      mat-button
      (click)="onServicesClick()">
      <h3 class="irt-no-margin">Vedi tutti i servizi</h3>
    </button>
  </div>
</div>
