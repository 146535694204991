import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Reservation } from 'src/app/core/reservation.service';
import {
  ResourcesService,
  VehicleCategory,
  VehiclePriceConfig,
} from 'src/app/core/resources.service';
import { IVA } from 'src/app/shared/constants';

@Component({
  selector: 'app-reservation-category-item',
  templateUrl: './reservation-category-item.component.html',
  styleUrls: ['./reservation-category-item.component.scss'],
})
export class ReservationCategoryItemComponent implements OnInit {
  @Input() category: VehicleCategory;
  @Input() reservation: Reservation;
  @Output() category_select_click: EventEmitter<VehicleCategory> =
    new EventEmitter<VehicleCategory>();

  constructor(private resources_service: ResourcesService) {}

  ngOnInit(): void {}

  onCategoryClick(category: VehicleCategory): void {
    this.category_select_click.emit(category);
  }

  getCategoryPrice(category: VehicleCategory): number {
    return this.resources_service.getCategoryPrice(
      category,
      this.reservation.start_date,
      this.reservation.end_date
    );
  }
}
