import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractRoutingModule } from './contract-routing.module';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

import { ContractComponent } from './contract.component';
import { ContractHeaderComponent } from './contract-header/contract-header.component';
import { ContractGeneralComponent } from './contract-general/contract-general.component';
import { ContractOwnerComponent } from './contract-owner/contract-owner.component';
import { ContractDriversComponent } from './contract-drivers/contract-drivers.component';
import { ContractVehicleComponent } from './contract-vehicle/contract-vehicle.component';
import { ContractLicenseComponent } from './contract-license/contract-license.component';
import { ContractServicesComponent } from './contract-services/contract-services.component';
import { ContractDamagesComponent } from './contract-damages/contract-damages.component';
import { ContractAmountComponent } from './contract-amount/contract-amount.component';
import { ContractConditionsComponent } from './contract-conditions/contract-conditions.component';
import { ContractNotesComponent } from './contract-notes/contract-notes.component';

@NgModule({
  declarations: [
    ContractComponent,
    ContractHeaderComponent,
    ContractGeneralComponent,
    ContractOwnerComponent,
    ContractDriversComponent,
    ContractVehicleComponent,
    ContractLicenseComponent,
    ContractServicesComponent,
    ContractDamagesComponent,
    ContractAmountComponent,
    ContractConditionsComponent,
    ContractNotesComponent,
  ],
  imports: [
    CommonModule,
    ContractRoutingModule,
    MatProgressSpinnerModule,
    MatButtonModule,
  ],
})
export class ContractModule {}
