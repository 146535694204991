/** Modules **/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesRoutingModule } from './services-routing.module';
import { SharedModule } from '../shared/shared.module';

/** Material **/
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

/** Components **/
import { ServicesComponent } from './services.component';
import { ServicesHeroComponent } from './services-hero/services-hero.component';
import { ServicesListComponent } from './services-list/services-list.component';

@NgModule({
  declarations: [
    ServicesComponent,
    ServicesHeroComponent,
    ServicesListComponent,
  ],
  imports: [
    CommonModule,
    ServicesRoutingModule,
    SharedModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class ServicesModule {}
