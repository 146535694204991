<app-toolbar [currentItem]="currentItem"></app-toolbar>

<section
  id="irt-commerce-root-layout"
  class="irt-full-width">
  <app-commerce-hero></app-commerce-hero>
  <app-commerce-list></app-commerce-list>
</section>

<app-footer></app-footer>
