import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/core/dialog.service';
import { FormService } from 'src/app/core/form.service';
import {
  HOURS,
  HEADQUARTER_ADDRESS_VALUE,
  STATION_ADDRESS_VALUE,
  AIRPORT_ADDRESS_VALUE,
  RESERVATION_PAGE_PATH,
} from 'src/app/shared/constants';
import {
  HEADQUARTER_ADDRESS,
  STATION_ADDRESS,
  AIRPORT_ADDRESS,
  ERROR_DATES_MESSAGE,
} from 'src/app/shared/translations';

@Component({
  selector: 'app-home-hero-form',
  templateUrl: './home-hero-form.component.html',
  styleUrls: ['./home-hero-form.component.scss'],
})
export class HomeHeroFormComponent implements OnInit {
  @ViewChild('endDatePicker') picker: MatDatepicker<Date>;
  home_rental_form_group: FormGroup;
  matcher: ErrorStateMatcher;
  hours: string[] = HOURS;
  min_start_date: Date;
  places: string[] = [HEADQUARTER_ADDRESS, STATION_ADDRESS, AIRPORT_ADDRESS];
  places_values: string[] = [
    HEADQUARTER_ADDRESS_VALUE,
    STATION_ADDRESS_VALUE,
    AIRPORT_ADDRESS_VALUE,
  ];

  constructor(
    private form_service: FormService,
    private router: Router,
    private dialog_service: DialogService,
    private dialog: MatDialog
  ) {
    this.home_rental_form_group = this.form_service.home_rental_form_group;
    this.matcher = this.form_service.matcher;
    this.dialog_service.dialog = this.dialog;
  }

  ngOnInit(): void {
    this.min_start_date = new Date(
      this.home_rental_form_group.get('start_date').value
    );
  }

  onSearchClick(): void {
    if (this.isValidForm()) {
      // Read addresses
      const start_address: string =
        this.home_rental_form_group.get('start').value;
      const end_address: string = this.home_rental_form_group.get('end').value;

      // Read dates
      const start_date: Date = new Date(
        this.home_rental_form_group.get('start_date').value
      );
      const end_date: Date = new Date(
        this.home_rental_form_group.get('end_date').value
      );
      const start_hour: string =
        this.home_rental_form_group.get('start_hour').value;
      const end_hour: string =
        this.home_rental_form_group.get('end_hour').value;

      start_date.setHours(
        +start_hour.substring(0, start_hour.indexOf(':')),
        +start_hour.substring(start_hour.indexOf(':') + 1, start_hour.length)
      );
      end_date.setHours(
        +end_hour.substring(0, end_hour.indexOf(':')),
        +end_hour.substring(end_hour.indexOf(':') + 1, end_hour.length)
      );

      // Validate dates and redirect
      if (this.validateSearchDates(start_date, end_date)) {
        this.router.navigate([RESERVATION_PAGE_PATH], {
          queryParams: {
            start: start_address,
            end: end_address,
            start_date: start_date.getTime(),
            end_date: end_date.getTime(),
          },
        });
      } else {
        this.dialog_service.openErrorDialog(ERROR_DATES_MESSAGE);
      }
    }
  }

  isValidForm(): boolean {
    return this.form_service.validateForm(this.home_rental_form_group);
  }

  validateSearchDates(startDate: Date, endDate: Date): boolean {
    return (
      endDate.getTime() > startDate.getTime() + 3600000 &&
      startDate.getTime() > new Date().getTime() + 86400000
    );
  }

  getMinEndDate(): Date {
    const DAY_IN_MILLIS: number = 86400000;
    return new Date(
      new Date(this.home_rental_form_group.get('start_date').value).getTime() +
        DAY_IN_MILLIS
    );
  }
}
