<form
  class="irt-double-margin-top"
  [formGroup]="home_rental_form_group">
  <mat-form-field
    class="irt-full-width"
    appearance="outline">
    <mat-label> Luogo di ritiro </mat-label>
    <mat-select
      formControlName="start"
      required>
      <mat-option [value]="places_values[0]">
        {{ places[0] }}
      </mat-option>
      <mat-option [value]="places_values[1]">
        {{ places[1] }}
      </mat-option>
      <mat-option [value]="places_values[2]">
        {{ places[2] }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    class="irt-full-width"
    appearance="outline">
    <mat-label> Luogo di consegna </mat-label>
    <mat-select
      formControlName="end"
      required>
      <mat-option [value]="places_values[0]">
        {{ places[0] }}
      </mat-option>
      <mat-option [value]="places_values[1]">
        {{ places[1] }}
      </mat-option>
      <mat-option [value]="places_values[2]">
        {{ places[2] }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="irt-form-row">
    <mat-form-field
      style="width: 65%"
      appearance="outline">
      <mat-label> Data inizio </mat-label>
      <input
        matInput
        type="text"
        required
        formControlName="start_date"
        [errorStateMatcher]="matcher"
        [min]="min_start_date"
        [matDatepicker]="start_date_picker" />
      <mat-datepicker-toggle
        matSuffix
        [for]="start_date_picker"></mat-datepicker-toggle>
      <mat-datepicker #start_date_picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field
      style="width: 30%"
      appearance="outline">
      <mat-label> Ora inizio </mat-label>
      <mat-select
        formControlName="start_hour"
        required>
        <mat-option
          value="{{ hour }}"
          *ngFor="let hour of hours">
          {{ hour }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="irt-form-row">
    <mat-form-field
      style="width: 65%"
      appearance="outline">
      <mat-label> Data fine </mat-label>
      <input
        matInput
        type="text"
        required
        formControlName="end_date"
        [errorStateMatcher]="matcher"
        [min]="getMinEndDate()"
        [matDatepicker]="end_date_picker" />
      <mat-datepicker-toggle
        matSuffix
        [for]="end_date_picker"></mat-datepicker-toggle>
      <mat-datepicker #end_date_picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field
      style="width: 30%"
      appearance="outline">
      <mat-label> Ora fine </mat-label>
      <mat-select
        formControlName="end_hour"
        required>
        <mat-option
          value="{{ hour }}"
          *ngFor="let hour of hours">
          {{ hour }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <button
    class="irt-full-width irt-main-button irt-double-margin-top irt-half-margin-bottom"
    mat-button
    (click)="onSearchClick()">
    <h3 class="irt-no-margin">Cerca</h3>
  </button>

  <p
    style="font-size: 12px"
    class="irt-centered-text irt-no-margin irt-primary-medium-color">
    *Il ritiro e la consegna a Catania prevedono un sovrapprezzo di 40€ per
    tratta
  </p>
</form>
