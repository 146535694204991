import { Component, Input, OnInit } from '@angular/core';
import { Reservation } from 'src/app/core/reservation.service';

@Component({
  selector: 'app-contract-general',
  templateUrl: './contract-general.component.html',
  styleUrls: ['./contract-general.component.scss'],
})
export class ContractGeneralComponent implements OnInit {
  @Input() reservation: Reservation;

  constructor() {}

  ngOnInit(): void {}
}
