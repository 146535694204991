import { Component, OnInit } from '@angular/core';
import { FAQ_PAGE_NAME, FAQS } from '../shared/translations';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  faqs: { question: string; answer: string }[] = FAQS;
  currentItem: string = FAQ_PAGE_NAME;
  logo = '../../../assets/imgs/irent-logo-light.png';

  constructor() {}

  ngOnInit() {}
}
