<div
  class="irt-primary-medium-background irt-border-radius irt-half-padding"
  style="position: relative">
  <h6
    class="irt-no-margin"
    style="position: absolute; top: -12px; left: 0">
    <u> 02.Intestatario contratto </u>
  </h6>

  <div class="irt-full-width irt-spaced-row">
    <h6 class="irt-no-margin irt-primary-medium-color">NOME</h6>
    <h6 class="irt-no-margin">
      {{ customer.first_name }} {{ customer.last_name }}
    </h6>
  </div>

  <div class="irt-full-width irt-spaced-row irt-half-margin-top">
    <h6 class="irt-no-margin irt-primary-medium-color">INDIRIZZO</h6>
    <h6
      class="irt-no-margin"
      *ngIf="customer.city || customer.province">
      {{ customer.address }} - {{ customer.city }} ({{ customer.province }})
    </h6>
    <h6
      class="irt-no-margin"
      *ngIf="!customer.city && !customer.province">
      {{ customer.address }}
    </h6>
  </div>

  <div class="irt-full-width irt-spaced-row irt-half-margin-top">
    <h6 class="irt-no-margin irt-primary-medium-color">CODICE FISCALE</h6>
    <h6 class="irt-no-margin">{{ customer.code }}</h6>
  </div>

  <div class="irt-full-width irt-spaced-row irt-half-margin-top">
    <h6 class="irt-no-margin irt-primary-medium-color">P. IVA</h6>
    <h6 class="irt-no-margin">{{ customer.vat }}</h6>
  </div>
</div>
