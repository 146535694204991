import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Reservation } from '../../core/reservation.service';
import { ResourcesService, Service } from '../../core/resources.service';

@Component({
  selector: 'app-reservation-services',
  templateUrl: './reservation-services.component.html',
  styleUrls: ['./reservation-services.component.scss'],
})
export class ReservationServicesComponent implements OnInit {
  @Input() reservation: Reservation;
  @Output() services_select_click: EventEmitter<Reservation> =
    new EventEmitter<Reservation>();
  services: Service[];
  selected_services: Service[] = [];

  constructor(private resources_service: ResourcesService) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.services = this.resources_service.readServices();
    this.selected_services = [];
  }

  onServiceCheckChange(service: Service) {
    let index: number = this.selected_services.indexOf(service);
    if (index != -1) {
      this.selected_services.splice(index, 1);
    } else {
      this.selected_services.push(service);
    }
  }

  onServiceConfirmClick() {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    this.reservation.services = this.selected_services;
    if (this.selected_services && this.selected_services.length > 0) {
      this.reservation.payment.amount =
        (this.reservation.payment.amount / 100 +
          this.reservation.services.map((s) => s.price).reduce(reducer)) *
        100;
    }
    this.services_select_click.emit(this.reservation);
  }

  isServiceChecked(service: Service) {
    let index: number = this.selected_services.indexOf(service);
    return index != -1;
  }
}
