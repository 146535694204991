import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Reservation } from '../../core/reservation.service';
import { VehicleService } from '../../core/vehicle.service';
import {
  CATEGORIES,
  ResourcesService,
  VehicleCategory,
  VehiclePriceConfig,
} from '../../core/resources.service';
import {
  AIRPORT_ADDRESS_VALUE,
  ERROR_404_CODE,
  EXTRA_DEFAULT_COST,
  IVA,
} from '../../shared/constants';

@Component({
  selector: 'app-reservation-category',
  templateUrl: './reservation-category.component.html',
  styleUrls: ['./reservation-category.component.scss'],
})
export class ReservationCategoryComponent implements OnInit {
  @Input() reservation: Reservation;
  @Output() category_select_click: EventEmitter<Reservation> =
    new EventEmitter<Reservation>();
  categories: VehicleCategory[];

  constructor(
    private vehicle_service: VehicleService,
    private resources_service: ResourcesService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.listAvailableVehicles();
  }

  onCategoryClick(category: VehicleCategory): void {
    this.reservation.vehicle_category = category.name;
    this.reservation.payment.amount += this.getCategoryPrice(category);
    this.category_select_click.emit(this.reservation);
  }

  listAvailableVehicles(): void {
    this.vehicle_service.listAvailableVehicles(this.reservation).subscribe({
      next: (response) => {
        let availableCategories: string[] = response.data.map(
          (v) => v.content.category
        );
        this.categories = CATEGORIES.filter((c) => {
          return availableCategories.includes(c.name);
        });
      },
      error: (error) => {
        if (error.response.data.error.code == ERROR_404_CODE) {
          this.categories = [];
        }
      },
    });
  }

  getCategoryPrice(category: VehicleCategory): number {
    return this.resources_service.getCategoryPrice(
      category,
      this.reservation.start_date,
      this.reservation.end_date
    );
  }

  getDefaultAmount(): number {
    let default_amount: number;
    default_amount =
      this.reservation.start_address == AIRPORT_ADDRESS_VALUE
        ? EXTRA_DEFAULT_COST
        : 0;
    default_amount +=
      this.reservation.end_address == AIRPORT_ADDRESS_VALUE
        ? EXTRA_DEFAULT_COST
        : 0;
    return default_amount;
  }
}
