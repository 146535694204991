import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Resource } from '../core/core.module';
import {
  Reservation,
  ReservationService,
  User,
} from '../core/reservation.service';
import { Vehicle } from '../core/vehicle.service';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
})
export class ContractComponent implements OnInit {
  token: string;
  uri: string;
  reservation: Reservation;
  vehicle: Vehicle;
  customer: User;
  amount: { net: number; service: number; vat: number; total: number };
  showButtonSpinner: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reservationService: ReservationService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['uri'] && params['token']) {
        this.token = params['token'];
        this.readReservation(params['uri']);
      } else {
        this.router.navigate(['/home']);
      }
    });
  }

  onSignClick() {
    this.showButtonSpinner = true;
    this.reservationService.signContract(this.token, this.uri).subscribe(
      (response) => {
        this.showButtonSpinner = false;
        this.router.navigate(['/home'], {
          queryParams: { contract_dialog: 'show' },
        });
      },
      (error) => {
        this.showButtonSpinner = false;
      }
    );
  }

  readReservation(uri: string) {
    this.reservationService.readReservation(uri).subscribe(
      (response) => {
        this.uri = (response.data as Resource).key.uri;
        this.reservation = (response.data as Resource).content as Reservation;
        this.vehicle = (response.data as Resource).links[0].resource
          .content as Vehicle;
        this.customer = this.reservation.customer;
        this.setContractAmount();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  setContractAmount(): void {
    const amount = this.reservation.payment?.amount || 0;
    const services_amount = this.reservation.services.reduce(
      (previousValue, currentValue) => previousValue + currentValue.price,
      0
    );
    const services_net_amount = services_amount / 1.22;
    const services_vat_amount = services_amount - services_net_amount;
    const net_amount = amount / 1.22;
    const vat_amount = amount - net_amount;
    const total_amount = services_amount + amount;
    const total_vat_amount = services_vat_amount + vat_amount;

    this.amount = {
      net: net_amount,
      service: services_net_amount,
      vat: total_vat_amount,
      total: total_amount,
    };
  }

  getDisabledClass() {
    return {
      'irt-main-button': !this.showButtonSpinner,
      'irt-main-disabled-button': this.showButtonSpinner,
    };
  }
}
