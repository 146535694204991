/** Modules **/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommerceRoutingModule } from './commerce-routing.module';
import { SharedModule } from '../shared/shared.module';

/** Material **/
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/** Components **/
import { CommerceComponent } from './commerce.component';
import { CommerceHeroComponent } from './commerce-hero/commerce-hero.component';
import { CommerceListComponent } from './commerce-list/commerce-list.component';

@NgModule({
  declarations: [
    CommerceComponent,
    CommerceHeroComponent,
    CommerceListComponent,
  ],
  imports: [
    CommonModule,
    CommerceRoutingModule,
    SharedModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
})
export class CommerceModule {}
