<app-toolbar
  [currentItem]="currentItem"
  [logo]="logo"
  [forceBlackToolbar]="true"></app-toolbar>

<div id="irt-faq-root-layout">
  <h1 class="irt-centered-text">Domande frequenti</h1>
  <div
    id="irt-faq-list-layout"
    class="irt-full-width"
    *ngIf="faqs && faqs.length > 0">
    <mat-expansion-panel
      id="irt-faq-list-item-layout"
      *ngFor="let faq of faqs">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span style="font-size: 16px; margin: 6px 0">
            {{ faq.question }}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p>{{ faq.answer }}</p>
    </mat-expansion-panel>
  </div>
</div>

<app-home-social></app-home-social>

<app-footer></app-footer>
