import { Component, OnInit } from '@angular/core';
import { ResourcesService, Service } from '../../core/resources.service';
import { Router } from '@angular/router';
import { CONTACTS_PAGE_PATH, SERVICES_PAGE_PATH } from '../../shared/constants';
import { InfoDialogComponent } from '../../shared/info-dialog/info-dialog.component';
import {
  CONTACT_DIALOG_CTA,
  CONTACT_DIALOG_MESSAGE,
  CONTACT_DIALOG_TITLE,
} from '../../shared/translations';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home-services',
  templateUrl: './home-services.component.html',
  styleUrls: ['./home-services.component.scss'],
})
export class HomeServicesComponent implements OnInit {
  services: Service[];

  constructor(
    private dialog_service: DialogService,
    private dialog: MatDialog,
    private resources_service: ResourcesService,
    private router: Router
  ) {
    this.dialog_service.dialog = this.dialog;
  }

  ngOnInit() {
    this.services = this.resources_service.readServices().slice(0, 3);
  }

  onInfoClick() {
    this.dialog_service
      .openDialog(InfoDialogComponent, {
        title: CONTACT_DIALOG_TITLE,
        message: CONTACT_DIALOG_MESSAGE,
        cta: CONTACT_DIALOG_CTA,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult && dialogResult.confirm) {
          this.router.navigate([CONTACTS_PAGE_PATH]);
        }
      });
  }

  onServicesClick() {
    this.router.navigate([SERVICES_PAGE_PATH]);
  }
}
