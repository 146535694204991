import { Component, OnInit } from '@angular/core';
import { CONTACTS_PAGE_NAME } from '../shared/translations';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
  currentItem: string = CONTACTS_PAGE_NAME;

  constructor() {}

  ngOnInit() {}
}
