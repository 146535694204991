<div
  id="irt-contacts-form-root-layout"
  class="irt-full-height">
  <form
    id="irt-contacts-form"
    [formGroup]="formGroup"
    class="irt-full-height irt-border-radius">
    <h2>Scrivici una mail!</h2>
    <div class="irt-full-width">
      <mat-form-field
        appearance="outline"
        class="irt-full-width">
        <mat-label> Nome </mat-label>
        <input
          matInput
          type="text"
          formControlName="first_name"
          [errorStateMatcher]="matcher"
          required />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="irt-full-width">
        <mat-label> Cognome </mat-label>
        <input
          matInput
          type="text"
          formControlName="last_name"
          [errorStateMatcher]="matcher"
          required />
      </mat-form-field>
    </div>

    <mat-form-field
      appearance="outline"
      class="irt-full-width">
      <mat-label> Email </mat-label>
      <input
        matInput
        type="email"
        formControlName="email"
        [errorStateMatcher]="matcher"
        required />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="irt-full-width">
      <mat-label> Messaggio </mat-label>
      <textarea
        matInput
        type="email"
        formControlName="message"
        [errorStateMatcher]="matcher"
        required></textarea>
    </mat-form-field>

    <button
      class="irt-full-width irt-main-button irt-half-margin-bottom"
      mat-button
      (click)="onSendEmailClick()">
      <span *ngIf="!showButtonSpinner"> INVIA </span>
      <mat-spinner
        style="margin: 6px auto"
        [diameter]="20"
        color="accent"
        *ngIf="showButtonSpinner"></mat-spinner>
    </button>
    <p
      style="font-size: 12px"
      class="irt-centered-text irt-full-width irt-no-margin">
      *Inviando la mail permetto ad IRent di processare le mie informazioni
      personali e acconsento ad IRent di inviarmi comunicazioni di marketing
    </p>
  </form>
</div>
