<div class="irt-full-width">
  <h1 class="irt-centered-text">
    Completa il pagamento per confermare la tua prenotazione!
  </h1>
  <div
    class="irt-centered-row irt-full-width irt-flex-wrap"
    *ngIf="reservation">
    <div
      class="irt-accent-background irt-half-padding irt-half-margin irt-border-radius">
      <p class="irt-primary-color irt-no-margin">
        {{ reservation.start_date | date : 'short' }} -
        {{ reservation.end_date | date : 'short' }}
      </p>
    </div>

    <div
      class="irt-accent-background irt-half-padding irt-half-margin irt-border-radius">
      <p class="irt-primary-color irt-no-margin">
        Categoria {{ reservation.vehicle_category }} - Prezzo
        {{ reservation.payment.amount / 100 | number : '1.0-2' : 'it-IT' }}€
      </p>
    </div>
  </div>

  <p
    id="irt-reservation-overprice-text"
    class="irt-centered-text irt-no-margin irt-primary-medium-color"
    *ngIf="reservation">
    *Sovrapprezzo per Catania:
    {{ getDefaultAmount() / 100 | number : '1.0-2' : 'it-IT' }}€
  </p>

  <div
    id="irt-reservation-payment-card-layout"
    class="irt-margin-top irt-border-radius"
    [ngClass]="isCardVisible()">
    <div id="irt-reservation-payment-card"></div>
    <button
      mat-button
      class="irt-main-button irt-full-width irt-margin-top"
      [disabled]="show_button_spinner"
      [ngClass]="getDisabledClass()"
      (click)="onConfirmPaymentClick()">
      <span *ngIf="!show_button_spinner"> Paga ora </span>
      <div
        class="irt-spinner-layout"
        *ngIf="show_button_spinner">
        <mat-spinner
          [diameter]="20"
          color="accent">
        </mat-spinner>
      </div>
    </button>
  </div>

  <div
    class="irt-full-width irt-centered-row irt-double-margin-top"
    style="position: absolute; left: 0; top: calc(50% - 36px)"
    *ngIf="!reservation">
    <div
      class="irt-padding irt-border-radius"
      style="background: #f4f4f4">
      <mat-spinner
        [diameter]="30"
        color="accent">
      </mat-spinner>
    </div>
  </div>
</div>
