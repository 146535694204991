import { Component } from '@angular/core';
import { DESTINATIONS_PAGE_NAME } from '../shared/translations';

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss'],
})
export class DestinationsComponent {
  currentItem: string = DESTINATIONS_PAGE_NAME;
}
