import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SERVICES_PAGE_PATH } from '../../shared/constants';

@Component({
  selector: 'app-home-intro',
  templateUrl: './home-intro.component.html',
  styleUrls: ['./home-intro.component.scss'],
})
export class HomeIntroComponent {
  constructor(private router: Router) {}

  onDiscoverClick() {
    this.router.navigate([SERVICES_PAGE_PATH]);
  }

  onReserveClick() {
    window.scrollTo(0, 0);
  }
}
