<div
  id="irt-services-list-root-layout"
  class="irt-full-width">
  <div
    id="irt-services-list-layout"
    class="irt-full-width">
    <div
      id="irt-services-list-item-layout"
      *ngFor="let service of services">
      <div
        id="irt-services-list-item-bar"
        class="irt-full-width"></div>

      <div
        id="irt-services-list-item-card"
        class="irt-border-radius"
        *ngIf="service.title && service.title != ''">
        <div
          id="irt-services-list-item-card-img-layout"
          [ngStyle]="{
            'background-image': 'url(' + service.img_url + ')'
          }"></div>

        <div id="irt-services-list-item-card-content-layout">
          <div class="irt-full-width">
            <h3 class="irt-no-margin">{{ service.title }}</h3>
            <p
              class="irt-no-margin irt-primary-medium-color irt-ellipsized-text">
              {{ service.description }}
            </p>
          </div>
          <button
            id="irt-services-list-item-card-button"
            class="irt-main-button"
            mat-button
            (click)="onServiceClick()">
            <span> Maggiori informazioni </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
