<div
  class="irt-full-width irt-primary-medium-background irt-border-radius irt-half-padding"
  style="position: relative; min-height: 80px">
  <h6
    class="irt-no-margin"
    style="position: absolute; top: -12px; left: 0">
    <u> 03.Autisti </u>
  </h6>

  <table class="irt-full-width">
    <tr>
      <th>
        <h6
          class="irt-no-margin irt-contract-drivers-th irt-primary-medium-color">
          Nome
        </h6>
      </th>
      <th>
        <h6
          class="irt-no-margin irt-contract-drivers-th irt-primary-medium-color">
          Indirizzo
        </h6>
      </th>
      <th>
        <h6
          class="irt-no-margin irt-contract-drivers-th irt-primary-medium-color">
          Telefono
        </h6>
      </th>
      <th>
        <h6
          class="irt-no-margin irt-contract-drivers-th irt-primary-medium-color">
          Luogo di nascita
        </h6>
      </th>
      <th>
        <h6
          class="irt-no-margin irt-contract-drivers-th irt-primary-medium-color">
          Data di nascita
        </h6>
      </th>
    </tr>
    <tr>
      <td>
        <h6 class="irt-no-margin">
          {{ customer.first_name }} {{ customer.last_name }}
        </h6>
      </td>
      <td>
        <h6
          class="irt-no-margin"
          *ngIf="customer.city || customer.province">
          {{ customer.address }} - {{ customer.city }} ({{ customer.province }})
        </h6>
      </td>
      <td>
        <h6
          class="irt-no-margin"
          *ngIf="!customer.city && !customer.province">
          {{ customer.address }}
        </h6>
      </td>
      <td>
        <h6 class="irt-no-margin">
          +{{ customer.phone.prefix }} {{ customer.phone.value }}
        </h6>
      </td>
      <td><h6 class="irt-no-margin"></h6></td>
      <td><h6 class="irt-no-margin"></h6></td>
    </tr>
  </table>
</div>
