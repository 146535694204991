<div
  id="irt-reservation-category-item-card"
  class="irt-full-width irt-border-radius">
  <div
    class="irt-reservation-category-item-card-img-layout"
    [ngStyle]="{ 'background-image': 'url(' + category.img_url + ')' }">
    <div
      id="irt-reservation-category-item-price-layout"
      class="irt-accent-background irt-border-radius">
      <span class="irt-primary-color">
        {{ getCategoryPrice(category) / 100 | number : '1.0-2' : 'it-IT' }}€
      </span>
    </div>
  </div>
  <div class="irt-reservation-category-item-card-content-layout">
    <div class="irt-full-width">
      <h3 class="irt-no-margin">Categoria {{ category.name }}</h3>
      <p class="irt-no-margin irt-primary-medium-color irt-ellipsized-text">
        {{ category.description }}
      </p>
    </div>
    <button
      class="irt-reservation-category-item-card-button irt-main-button"
      (click)="onCategoryClick(category)"
      mat-button>
      <span> Seleziona </span>
    </button>
  </div>
</div>
