import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { LG_SCREEN_WIDTH, SLIDESHOW_BACKGROUNDS } from '../../shared/constants';

@Component({
  selector: 'app-home-hero',
  templateUrl: './home-hero.component.html',
  styleUrls: ['./home-hero.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class HomeHeroComponent implements OnInit {
  @Output() background_index_change: EventEmitter<number> =
    new EventEmitter<number>();

  background_interval: NodeJS.Timeout;
  current_background_index: number = 0;
  slideshow_interval_length: number = 5000;
  backgrounds: string[] = SLIDESHOW_BACKGROUNDS;

  fab_color: string;

  constructor(private dialogService: DialogService, private dialog: MatDialog) {
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {
    this.setupBackgroundInterval();
    if (window.innerWidth >= LG_SCREEN_WIDTH) {
      this.fab_color = 'accent';
    } else {
      this.fab_color = 'primary';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (window.innerWidth >= LG_SCREEN_WIDTH) {
      this.fab_color = 'accent';
    } else {
      this.fab_color = 'primary';
    }
  }

  onPreviousClick() {
    clearInterval(this.background_interval);
    this.current_background_index--;
    this.background_index_change.emit(this.current_background_index);
    this.setupBackgroundInterval();
  }

  onNextClick() {
    clearInterval(this.background_interval);
    this.current_background_index++;
    this.background_index_change.emit(this.current_background_index);
    this.setupBackgroundInterval();
  }

  onScrollButtonClick() {
    let scrollToPos = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos < window.innerHeight - 20 || pos > window.innerHeight + 20) {
        if (pos > window.innerHeight) pos = pos - 20;
        else pos = pos + 20;
        window.scrollTo(0, pos);
      } else {
        window.clearInterval(scrollToPos);
      }
    }, 1);
  }

  setupBackgroundInterval() {
    this.background_interval = setInterval(() => {
      if (this.current_background_index >= this.backgrounds.length - 1) {
        this.current_background_index = 0;
      } else {
        this.current_background_index++;
      }
      this.background_index_change.emit(this.current_background_index);
    }, this.slideshow_interval_length);
  }
}
