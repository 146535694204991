import { Component, OnInit } from '@angular/core';
import { FACEBOOK_LINK, INSTAGRAM_LINK } from '../../shared/constants';

@Component({
  selector: 'app-contacts-info',
  templateUrl: './contacts-info.component.html',
  styleUrls: ['./contacts-info.component.scss'],
})
export class ContactsInfoComponent implements OnInit {
  facebookLink: string = FACEBOOK_LINK;
  instagramLink: string = INSTAGRAM_LINK;

  constructor() {}

  ngOnInit() {}

  onSocialLinkClick(link: string) {
    window.open(link, '_blank');
  }
}
