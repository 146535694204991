<app-toolbar
  [currentItem]="currentItem"
  [logo]="logo"></app-toolbar>

<section
  id="irt-home-root-layout"
  class="irt-full-width">
  <app-home-hero></app-home-hero>
  <app-home-social></app-home-social>
  <app-home-intro></app-home-intro>
  <app-home-services></app-home-services>
  <app-home-advantages></app-home-advantages>
</section>

<app-footer></app-footer>
