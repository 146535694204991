import { Component, Input, OnInit } from '@angular/core';
import { Reservation } from 'src/app/core/reservation.service';

@Component({
  selector: 'app-contract-services',
  templateUrl: './contract-services.component.html',
  styleUrls: ['./contract-services.component.scss'],
})
export class ContractServicesComponent implements OnInit {
  @Input() reservation: Reservation;

  constructor() {}

  ngOnInit(): void {}
}
