import { Component } from '@angular/core';
import { COMMERCE_PAGE_NAME } from '../shared/translations';

@Component({
  selector: 'app-commerce',
  templateUrl: './commerce.component.html',
  styleUrls: ['./commerce.component.scss'],
})
export class CommerceComponent {
  currentItem: string = COMMERCE_PAGE_NAME;
}
