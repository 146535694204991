import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CONTRACT_PAGE_PATH } from '../shared/constants';
import { ContractComponent } from './contract.component';

const routes: Routes = [
  {
    path: CONTRACT_PAGE_PATH,
    component: ContractComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContractRoutingModule {}
