<div
  id="irt-contacts-info-root-layout"
  class="irt-full-height">
  <div
    id="irt-contacts-info-content-layout"
    class="irt-full-height irt-border-radius">
    <div class="irt-contacts-info-layout irt-margin-bottom">
      <mat-icon> call </mat-icon>
      <p class="irt-no-margin irt-half-margin-left">+39 0931 1850575</p>
    </div>

    <div class="irt-contacts-info-layout irt-margin-bottom">
      <img src="../../../assets/imgs/contacts/irent-whatsapp-logo.png" />
      <p class="irt-no-margin irt-half-margin-left">+39 392 1801939</p>
    </div>

    <div class="irt-contacts-info-layout irt-margin-bottom">
      <mat-icon> warning_amber </mat-icon>
      <p class="irt-no-margin irt-half-margin-left">
        +39 327 9093333 (assistenza stradale)
      </p>
    </div>

    <div class="irt-contacts-info-layout irt-margin-bottom">
      <img
        class="irt-cursor-pointer irt-contacts-social-img"
        src="../../../assets/imgs/contacts/irent-facebook-logo.png"
        (click)="onSocialLinkClick(facebookLink)" />
      <img
        class="irt-cursor-pointer irt-contacts-social-img irt-margin-left"
        src="../../../assets/imgs/contacts/irent-instagram-logo.png"
        (click)="onSocialLinkClick(instagramLink)" />
    </div>
  </div>
</div>
