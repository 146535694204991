import { Injectable } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  home_rental_form_group = new FormBuilder().group({
    start: ['', [Validators.required]],
    end: ['', [Validators.required]],
    start_date: [
      new Date(new Date().getTime() + 86400000 * 2),
      [Validators.required],
    ],
    end_date: [
      new Date(new Date().getTime() + 86400000 * 3),
      [Validators.required],
    ],
    start_hour: ['08:00', [Validators.required]],
    end_hour: ['08:00', [Validators.required]],
  });

  contacts_from_group = new FormBuilder().group({
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    message: ['', [Validators.required]],
  });

  reservation_user_from_group: FormGroup = new FormBuilder().group({
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone_prefix: [''],
    phone: ['', [Validators.required]],
    code: ['', [Validators.required]],
    license_num: ['', [Validators.required]],
    license_date: ['', [Validators.required]],
    license_exp: ['', [Validators.required]],
    license_place: ['', [Validators.required]],
    address: ['', [Validators.required]],
    save: [''],
  });

  reservation_business_form_group: FormGroup = new FormBuilder().group({
    vat: ['', [Validators.required]],
    business_name: ['', [Validators.required]],
    dest_code: ['', [Validators.required]],
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone_prefix: [''],
    phone: ['', [Validators.required]],
    code: ['', [Validators.required]],
    license_num: ['', [Validators.required]],
    license_date: ['', [Validators.required]],
    license_exp: ['', [Validators.required]],
    license_place: ['', [Validators.required]],
    address: ['', [Validators.required]],
    save: [''],
  });

  matcher: FormErrorStateMatcher = new FormErrorStateMatcher();

  validateForm(formGroup: FormGroup) {
    let valid: boolean = true;
    for (let controlName of Object.keys(formGroup.controls)) {
      if (formGroup.get(controlName).invalid) {
        valid = false;
        formGroup.get(controlName).markAsTouched();
      }
    }
    return valid;
  }
}

export class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
