import { Component, HostListener, Input, OnInit } from '@angular/core';
import {
  COMMERCE_PAGE_NAME,
  CONTACTS_PAGE_NAME,
  DESTINATIONS_PAGE_NAME,
  HOME_PAGE_NAME,
  SERVICES_PAGE_NAME,
} from '../translations';
import { Router } from '@angular/router';
import {
  COMMERCE_PAGE_PATH,
  CONTACTS_PAGE_PATH,
  DESTINATIONS_PAGE_PATH,
  HOME_PAGE_PATH,
  SERVICES_PAGE_PATH,
} from '../constants';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class ToolbarComponent implements OnInit {
  @Input() currentItem: string;
  @Input() logo: string;
  @Input() logoSize: string;
  @Input() forceBlackToolbar: boolean;
  defaultLogo: string;
  lightLogo: string = '../../../assets/imgs/irent-logo-light.png';
  items: string[] = [
    HOME_PAGE_NAME,
    COMMERCE_PAGE_NAME,
    DESTINATIONS_PAGE_NAME,
    SERVICES_PAGE_NAME,
    CONTACTS_PAGE_NAME,
  ];
  isBlackToolbar: boolean = false;
  showFullScreenMenu: boolean = false;

  constructor(private router: Router) {}

  ngOnInit() {
    if (!this.logo) {
      this.logo = this.lightLogo;
    }
    this.defaultLogo = this.logo;
  }

  @HostListener('window:scroll', ['$event'])
  onPageScroll(event) {
    if (window.pageYOffset > 10) {
      this.isBlackToolbar = true;
      this.logo = this.lightLogo;
    } else {
      this.isBlackToolbar = false;
      this.logo = this.defaultLogo;
    }
  }

  onItemClick(item: string) {
    switch (item) {
      case HOME_PAGE_NAME:
        this.router.navigate([HOME_PAGE_PATH]);
        break;
      case DESTINATIONS_PAGE_NAME:
        this.router.navigate([DESTINATIONS_PAGE_PATH]);
        break;
      case SERVICES_PAGE_NAME:
        this.router.navigate([SERVICES_PAGE_PATH]);
        break;
      case CONTACTS_PAGE_NAME:
        this.router.navigate([CONTACTS_PAGE_PATH]);
        break;
      case COMMERCE_PAGE_NAME:
        this.router.navigate([COMMERCE_PAGE_PATH]);
        break;
    }
  }

  onFullScreenMenuToggleClick() {
    this.showFullScreenMenu = !this.showFullScreenMenu;
  }

  getToolbarClass() {
    return {
      'irt-toolbar-black': this.isBlackToolbar || this.forceBlackToolbar,
      'irt-toolbar-transparent':
        !this.isBlackToolbar && !this.forceBlackToolbar,
    };
  }

  getSortButtonClass() {
    return 'irt-primary-color';
  }

  getItemTextClass(item: string) {
    return {
      'irt-toolbar-menu-item-selected-text': item == this.currentItem,
      'irt-toolbar-menu-item-unselected-text': item != this.currentItem,
    };
  }

  getItemBarClass(item: string) {
    return {
      'irt-toolbar-menu-item-selected-bar': item == this.currentItem,
      'irt-toolbar-menu-item-unselected-bar': item != this.currentItem,
    };
  }

  getLogoClass() {
    return {
      'irt-toolbar-logo-large': this.logoSize && this.logoSize == 'large',
      'irt-toolbar-logo': !this.logoSize || this.logoSize != 'large',
    };
  }
}
