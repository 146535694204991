<div id="irt-footer-root-layout">
  <div id="irt-footer-gratings-layout">
    <img
      id="irt-footer-gratings-logo"
      src="../../../assets/imgs/irent-logo-dark.png" />

    <div id="irt-footer-gratings-text-layout">
      <h3
        id="irt-footer-gratings-text"
        class="irt-centered-text">
        Feel free to travel
      </h3>
    </div>

    <div id="irt-footer-gratings-actions-layout">
      <button
        class="irt-main-button irt-footer-gratings-button"
        mat-button
        (click)="onReserveClick()">
        <h3 class="irt-no-margin">Prenota un veicolo</h3>
      </button>
    </div>
  </div>

  <div id="irt-footer-info-layout">
    <img
      id="irt-footer-info-logo"
      src="../../../assets/imgs/irent-logo-light.png" />

    <div id="irt-footer-info-content-layout">
      <div class="irt-footer-info-content-layout">
        <h3 class="irt-primary-color irt-centered-text">Site map</h3>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterPageClick(homePath)">
          Home
        </p>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterPageClick(commercePath)">
          Vendita
        </p>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterPageClick(destinationsPath)">
          Destinazioni
        </p>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterPageClick(servicesPath)">
          Servizi
        </p>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterPageClick(contactsPath)">
          Contatti
        </p>
      </div>

      <div class="irt-footer-info-content-layout">
        <h3 class="irt-primary-color irt-centered-text">
          Termini e condizioni
        </h3>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterLinkClick(termsAndConditionsLink)">
          Termini generali
        </p>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterLinkClick(additionalCoverageLink)">
          Franchigie e condizioni generali coperture aggiuntive
        </p>
      </div>

      <div class="irt-footer-info-content-layout">
        <h3 class="irt-primary-color irt-centered-text">Sharing</h3>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterPageClick(pricingPath)">
          Prezzi
        </p>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterPageClick(faqPath)">
          FAQ
        </p>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterLinkClick(sharingConditionsLink)">
          Condizioni generali
        </p>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterLinkClick(sharingGuideLink)">
          Guida all'utilizzo
        </p>
      </div>

      <div class="irt-footer-info-content-layout">
        <h3 class="irt-primary-color irt-centered-text">Social</h3>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterLinkClick(facebookLink)">
          Facebook
        </p>
        <p
          class="irt-primary-color irt-centered-text irt-footer-info-content-text"
          (click)="onFooterLinkClick(instagramLink)">
          Instagram
        </p>
      </div>
    </div>

    <div id="irt-footer-info-vat-layout">
      <p class="irt-no-margin irt-primary-color">
        P.IVA. 01744200708 | I-RENT SRL
      </p>
    </div>
  </div>
</div>
