import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DestinationsComponent } from './destinations.component';
import { DESTINATIONS_PAGE_PATH } from '../shared/constants';

const routes: Routes = [
  {
    path: DESTINATIONS_PAGE_PATH,
    component: DestinationsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DestinationsRoutingModule {}
