<div
  class="irt-primary-medium-background irt-border-radius irt-half-padding"
  style="position: relative">
  <h6
    class="irt-no-margin"
    style="position: absolute; top: -12px; left: 0">
    <u> {{ getDamagesIndex() }}.{{ getDamagesTitle() }} </u>
  </h6>

  <div class="irt-spaced-row">
    <img
      src="../../assets/imgs/irent_vehicle_img.png"
      class="irt-half-width irt-border-radius" />
    <div class="irt-half-width">
      <h6
        class="irt-no-margin"
        *ngFor="let damage of vehicle.damages">
        {{ damage.description }}
      </h6>
    </div>
  </div>
</div>
