import { Component } from '@angular/core';
import {
  ANDROID_APP_LINK,
  APPLE_APP_LINK,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
} from '../../shared/constants';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home-social',
  templateUrl: './home-social.component.html',
  styleUrls: ['./home-social.component.scss'],
})
export class HomeSocialComponent {
  facebook_link: string = FACEBOOK_LINK;
  instagram_link: string = INSTAGRAM_LINK;

  constructor(private dialogService: DialogService, private dialog: MatDialog) {
    this.dialogService.dialog = this.dialog;
  }

  onIosAppClick() {
    window.open(APPLE_APP_LINK, '_blank');
  }

  onAndroidAppClick() {
    window.open(ANDROID_APP_LINK, '_blank');
  }

  onSocialLinkClick(link: string) {
    window.open(link, '_blank');
  }
}
