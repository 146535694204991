<div
  class="irt-primary-medium-background irt-border-radius irt-half-padding"
  style="position: relative">
  <h6
    class="irt-no-margin"
    style="position: absolute; top: -12px; left: 0">
    <u> 04.Veicolo assegnato </u>
  </h6>

  <div class="irt-full-width irt-spaced-row">
    <h6 class="irt-no-margin irt-primary-medium-color">MARCA</h6>
    <h6 class="irt-no-margin">{{ vehicle.brand }}</h6>
  </div>

  <div class="irt-full-width irt-spaced-row irt-half-margin-top">
    <h6 class="irt-no-margin irt-primary-medium-color">MODELLO</h6>
    <h6 class="irt-no-margin">{{ vehicle.model }}</h6>
  </div>

  <div class="irt-full-width irt-spaced-row irt-half-margin-top">
    <h6 class="irt-no-margin irt-primary-medium-color">TARGA</h6>
    <h6 class="irt-no-margin">{{ vehicle.plate }}</h6>
  </div>

  <div class="irt-full-width irt-spaced-row irt-half-margin-top">
    <h6 class="irt-no-margin irt-primary-medium-color">KM USCITA</h6>
    <h6 class="irt-no-margin">{{ vehicle.km }}km</h6>
  </div>
</div>
