<div>
  <h1 class="irt-centered-text">
    Vuoi aggiungere uno dei nostri servizi supplementari?
  </h1>
  <div class="irt-centered-row irt-full-width irt-flex-wrap">
    <div class="irt-accent-background irt-half-padding irt-half-margin-bottom">
      <p class="irt-primary-color irt-no-margin">
        {{ reservation.start_date | date : 'short' }} -
        {{ reservation.end_date | date : 'short' }}
      </p>
    </div>

    <div class="irt-accent-background irt-half-padding irt-half-margin-bottom">
      <p class="irt-primary-color irt-no-margin">
        Categoria {{ reservation.vehicle_category }} - Prezzo
        {{ reservation.payment.amount / 100 | number : '1.0-2' : 'it-IT' }}€
      </p>
    </div>
  </div>

  <div
    id="irt-reservation-services-list-layout"
    class="irt-full-width"
    *ngIf="services && services.length > 0">
    <div
      id="irt-reservation-services-item-layout"
      *ngFor="let service of services">
      <div
        id="irt-reservation-services-item-card"
        class="irt-full-width">
        <div
          class="irt-reservation-services-item-card-img-layout"
          [ngStyle]="{
            'background-image': 'url(' + service.img_url + ')'
          }"></div>

        <div class="irt-reservation-services-item-card-content-layout">
          <div class="irt-full-width irt-centered-row">
            <mat-checkbox
              class="prepay-privacy-radio-layout prepay-half-margin-top"
              [checked]="isServiceChecked(service)"
              (change)="onServiceCheckChange(service)">
            </mat-checkbox>
            <h3 class="irt-no-margin irt-margin-left">
              {{ service.title }} -
              {{ service.price | number : '1.0-2' : 'it-IT' }}€
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="irt-full-width irt-centered-column">
    <p *ngIf="selected_services.length == 1">
      {{ selectedServices.length }} servizio selezionato
    </p>
    <p *ngIf="selected_services.length != 1">
      {{ selectedServices.length }} servizi selezionati
    </p>
    <button
      class="irt-main-button"
      mat-button
      style="min-width: 200px"
      (click)="onServiceConfirmClick()">
      <span> Prosegui </span>
    </button>
  </div>
</div>
