import { Component, HostListener, OnInit } from '@angular/core';
import { MD_SCREEN_WIDTH } from '../../shared/constants';
import { Vehicle, VehicleService } from '../../core/vehicle.service';
import { Storage } from 'aws-amplify';
import { AnimationPlayer } from '@angular/animations';

@Component({
  selector: 'app-commerce-list',
  templateUrl: './commerce-list.component.html',
  styleUrls: ['./commerce-list.component.scss'],
})
export class CommerceListComponent implements OnInit {
  vehicles: Vehicle[];
  vehicleImages: { vehicle: Vehicle; url: string }[] = [];

  constructor(private vehiclesService: VehicleService) {}

  ngOnInit() {
    this.vehiclesService.findVehicles(true).subscribe(
      (response) => {
        this.vehicles = response.data.data.map((r) => r.content);
        this.vehicles.map((v) => this.setImageUrl(v));
        if (
          this.vehicles.length > 1 &&
          this.vehicles.length % 2 != 0 &&
          window.innerWidth >= MD_SCREEN_WIDTH
        ) {
          this.vehicles = this.vehicles.concat(new Vehicle());
        }
      },
      (error) => {}
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: AnimationPlayer) {
    if (
      this.vehicles &&
      this.vehicles.length % 2 != 0 &&
      window.innerWidth >= MD_SCREEN_WIDTH
    ) {
      this.vehicles = this.vehicles.concat(new Vehicle());
    }
  }

  onVehicleClick(vehicle: Vehicle) {
    window.open(vehicle.link, '_blank');
  }

  setImageUrl(vehicle: Vehicle) {
    Storage.get(vehicle.img_url, { level: 'public' })
      .then((result) => {
        this.vehicleImages.push({
          vehicle: vehicle,
          url: result.toString(),
        });
      })
      .catch((err) => {
        this.vehicleImages.push({
          vehicle: vehicle,
          url: 'https://irent-front-storage.s3.amazonaws.com/alfa-giulia-2016.jpeg',
        });
      });
  }

  getImageUrl(vehicle: Vehicle) {
    let vehicleImage = this.vehicleImages.filter((vi) => {
      return vi.vehicle.plate == vehicle.plate;
    })[0];
    if (vehicleImage) {
      return vehicleImage.url;
    }
    return 'https://irent-front-storage.s3.amazonaws.com/alfa-giulia-2016.jpeg';
  }
}
