import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent {
  title: string;
  message: string;
  cta: string;

  constructor(
    private dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.message = data.message;
    this.cta = data.cta;
  }

  onDialogCloseClick() {
    this.dialogRef.close();
  }

  onDialogConfirmClick() {
    this.dialogRef.close({ confirm: true });
  }
}
