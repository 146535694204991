import { Component, HostListener, OnInit } from '@angular/core';
import { Destination, ResourcesService } from '../../core/resources.service';
import { MD_SCREEN_WIDTH } from '../../shared/constants';

@Component({
  selector: 'app-destinations-list',
  templateUrl: './destinations-list.component.html',
  styleUrls: ['./destinations-list.component.scss'],
})
export class DestinationsListComponent implements OnInit {
  destinations: Destination[] = [];

  constructor(private resourcesService: ResourcesService) {}

  ngOnInit() {
    this.destinations = this.resourcesService.readDestinations();
    if (
      this.destinations.length % 2 != 0 &&
      window.innerWidth >= MD_SCREEN_WIDTH
    ) {
      this.destinations = this.destinations.concat(new Destination());
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.destinations = this.resourcesService.readDestinations();
    if (
      this.destinations.length % 2 != 0 &&
      window.innerWidth >= MD_SCREEN_WIDTH
    ) {
      this.destinations = this.destinations.concat(new Destination());
    }
  }
}
