import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  readDestinations() {
    return DESTINATIONS;
  }

  readServices() {
    return SERVICES;
  }

  getCategoryPrice(
    category: VehicleCategory,
    start_date: number,
    end_date: number
  ): number {
    // Prepare time related constants
    const DAY_IN_MILLIS: number = 86400000;
    const LOW_SEASON_MONTHS: number[] = [10, 11, 12, 1, 2, 3];
    const MID_SEASON_MONTHS: number[] = [4, 5, 9];
    //const HIGH_SEASON_MONTHS: number[] = [6, 7, 8];

    // Prepare time related variables
    const month: number = new Date(start_date).getMonth() + 1;
    const days: number = Math.ceil((end_date - start_date) / DAY_IN_MILLIS);
    const season: 'low' | 'medium' | 'high' = LOW_SEASON_MONTHS.includes(month)
      ? 'low'
      : MID_SEASON_MONTHS.includes(month)
      ? 'medium'
      : 'high';

    // Load seasonal price configs
    let price_configs: VehiclePriceConfig[] = [];
    switch (season) {
      case 'low':
        price_configs = category.price_configs_low;
        break;
      case 'medium':
        price_configs = category.price_configs;
        break;
      case 'high':
        price_configs = category.price_configs_high;
        break;
    }

    // Get reference price config by day
    const daily_config: VehiclePriceConfig = price_configs.filter((config) => {
      return config.days.includes(days);
    })[0];

    let daily_price: number;
    if (daily_config && daily_config.daily_price) {
      daily_price = daily_config.daily_price;
    } else {
      const min_price_config: VehiclePriceConfig =
        price_configs[price_configs.length - 1];
      daily_price = min_price_config.daily_price;
    }

    return daily_price * days;
    //return daily_price * days * (1 + IVA) * 1.3;
  }
}

export class Destination {
  id: number;
  img_url: string;
  title: string;
  description: string;
  link: string;
}

export class Service {
  id: number;
  img_url: string;
  title: string;
  description: string;
  link: string;
  price: number;
}

export class VehicleCategory {
  name: string;
  description: string;
  img_url: string;
  price_configs: VehiclePriceConfig[];
  price_configs_low: VehiclePriceConfig[];
  price_configs_high: VehiclePriceConfig[];
}

export class VehiclePriceConfig {
  days: number[];
  daily_price: number;
}

/** VEHICLE CATEGORIES **/
export const CATEGORIES: VehicleCategory[] = [
  {
    name: 'A',
    description: 'Fiat 500 o simili',
    img_url: '../../assets/imgs/vehicle-categories/irent-category-A.png',
    price_configs: [
      { days: [1, 2], daily_price: 3750 },
      { days: [3, 4, 5], daily_price: 3500 },
      { days: [6, 7], daily_price: 3125 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 2750 },
      { days: [15], daily_price: 2500 },
    ],
    price_configs_low: [
      { days: [1, 2], daily_price: 3000 },
      { days: [3, 4, 5], daily_price: 2800 },
      { days: [6, 7], daily_price: 2500 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 2200 },
      { days: [15], daily_price: 2000 },
    ],
    price_configs_high: [
      { days: [1, 2], daily_price: 4500 },
      { days: [3, 4, 5], daily_price: 4200 },
      { days: [6, 7], daily_price: 3750 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3300 },
      { days: [15], daily_price: 3000 },
    ],
  },
  {
    name: 'B',
    description: 'Fiat Panda o simili',
    img_url: '../../assets/imgs/vehicle-categories/irent-category-B.png',
    price_configs: [
      { days: [1, 2], daily_price: 4375 },
      { days: [3, 4, 5], daily_price: 4000 },
      { days: [6, 7], daily_price: 3500 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3125 },
      { days: [15], daily_price: 2750 },
    ],
    price_configs_low: [
      { days: [1, 2], daily_price: 3500 },
      { days: [3, 4, 5], daily_price: 3200 },
      { days: [6, 7], daily_price: 2800 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 2500 },
      { days: [15], daily_price: 2200 },
    ],
    price_configs_high: [
      { days: [1, 2], daily_price: 5250 },
      { days: [3, 4, 5], daily_price: 4800 },
      { days: [6, 7], daily_price: 4200 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3750 },
      { days: [15], daily_price: 3300 },
    ],
  },
  {
    name: 'C',
    description: 'Fiat Tipo o simili',
    img_url: '../../assets/imgs/vehicle-categories/irent-category-C.jpeg',
    price_configs: [
      { days: [1, 2], daily_price: 5000 },
      { days: [3, 4, 5], daily_price: 4500 },
      { days: [6, 7], daily_price: 4125 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3750 },
      { days: [15], daily_price: 3500 },
    ],
    price_configs_low: [
      { days: [1, 2], daily_price: 4000 },
      { days: [3, 4, 5], daily_price: 3600 },
      { days: [6, 7], daily_price: 3300 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3000 },
      { days: [15], daily_price: 2800 },
    ],
    price_configs_high: [
      { days: [1, 2], daily_price: 6000 },
      { days: [3, 4, 5], daily_price: 5400 },
      { days: [6, 7], daily_price: 4950 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 4500 },
      { days: [15], daily_price: 4200 },
    ],
  },
  {
    name: 'D',
    description: 'Fiat 500X o simili',
    img_url: '../../assets/imgs/vehicle-categories/irent-category-D.jpeg',
    price_configs: [
      { days: [1, 2], daily_price: 5875 },
      { days: [3, 4, 5], daily_price: 5250 },
      { days: [6, 7], daily_price: 4750 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 4375 },
      { days: [15], daily_price: 4000 },
    ],
    price_configs_low: [
      { days: [1, 2], daily_price: 4700 },
      { days: [3, 4, 5], daily_price: 4200 },
      { days: [6, 7], daily_price: 3800 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3500 },
      { days: [15], daily_price: 3200 },
    ],
    price_configs_high: [
      { days: [1, 2], daily_price: 7050 },
      { days: [3, 4, 5], daily_price: 6300 },
      { days: [6, 7], daily_price: 5700 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 5250 },
      { days: [15], daily_price: 4800 },
    ],
  },
  {
    name: 'E',
    description: 'Kia Sorento o simili',
    img_url: '../../assets/imgs/vehicle-categories/irent-category-E.jpeg',
    price_configs: [
      { days: [1, 2], daily_price: 6500 },
      { days: [3, 4, 5], daily_price: 5875 },
      { days: [6, 7], daily_price: 5250 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 4750 },
      { days: [15], daily_price: 4375 },
    ],
    price_configs_low: [
      { days: [1, 2], daily_price: 5200 },
      { days: [3, 4, 5], daily_price: 4700 },
      { days: [6, 7], daily_price: 4200 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3800 },
      { days: [15], daily_price: 3500 },
    ],
    price_configs_high: [
      { days: [1, 2], daily_price: 7800 },
      { days: [3, 4, 5], daily_price: 7050 },
      { days: [6, 7], daily_price: 6300 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 5700 },
      { days: [15], daily_price: 5250 },
    ],
  },
  {
    name: 'F',
    description: 'Peugeot 3008 o simili',
    img_url: '../../assets/imgs/vehicle-categories/irent-category-F.jpeg',
    price_configs: [
      { days: [1, 2], daily_price: 7125 },
      { days: [3, 4, 5], daily_price: 6625 },
      { days: [6, 7], daily_price: 5875 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 5250 },
      { days: [15], daily_price: 4875 },
    ],
    price_configs_low: [
      { days: [1, 2], daily_price: 5700 },
      { days: [3, 4, 5], daily_price: 5300 },
      { days: [6, 7], daily_price: 4700 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 4200 },
      { days: [15], daily_price: 3900 },
    ],
    price_configs_high: [
      { days: [1, 2], daily_price: 8550 },
      { days: [3, 4, 5], daily_price: 7950 },
      { days: [6, 7], daily_price: 7050 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 6300 },
      { days: [15], daily_price: 5850 },
    ],
  },
  {
    name: 'G',
    description: 'Alfa Romeo Stelvio o simili',
    img_url: '../../assets/imgs/vehicle-categories/irent-category-G.jpeg',
    price_configs: [
      { days: [1, 2], daily_price: 10000 },
      { days: [3, 4, 5], daily_price: 9375 },
      { days: [6, 7], daily_price: 8125 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 7500 },
      { days: [15], daily_price: 6875 },
    ],
    price_configs_low: [
      { days: [1, 2], daily_price: 8000 },
      { days: [3, 4, 5], daily_price: 7500 },
      { days: [6, 7], daily_price: 6500 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 6000 },
      { days: [15], daily_price: 5500 },
    ],
    price_configs_high: [
      { days: [1, 2], daily_price: 12000 },
      { days: [3, 4, 5], daily_price: 11250 },
      { days: [6, 7], daily_price: 9750 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 9000 },
      { days: [15], daily_price: 8250 },
    ],
  },
  {
    name: 'M',
    description: 'Fiat Doblo o simili',
    img_url: '../../assets/imgs/vehicle-categories/irent-category-M.jpeg',
    price_configs: [
      { days: [1, 2], daily_price: 4700 },
      { days: [3, 4, 5], daily_price: 4200 },
      { days: [6, 7], daily_price: 3800 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3500 },
      { days: [15], daily_price: 3200 },
    ],
    price_configs_low: [
      { days: [1, 2], daily_price: 4700 },
      { days: [3, 4, 5], daily_price: 4200 },
      { days: [6, 7], daily_price: 3800 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3500 },
      { days: [15], daily_price: 3200 },
    ],
    price_configs_high: [
      { days: [1, 2], daily_price: 4700 },
      { days: [3, 4, 5], daily_price: 4200 },
      { days: [6, 7], daily_price: 3800 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3500 },
      { days: [15], daily_price: 3200 },
    ],
  },
  {
    name: 'M1',
    description: 'Fiat Talento o simili',
    img_url: '../../assets/imgs/vehicle-categories/irent-category-M.jpeg',
    price_configs: [
      { days: [1, 2], daily_price: 5200 },
      { days: [3, 4, 5], daily_price: 4700 },
      { days: [6, 7], daily_price: 4200 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3800 },
      { days: [15], daily_price: 3500 },
    ],
    price_configs_low: [
      { days: [1, 2], daily_price: 5200 },
      { days: [3, 4, 5], daily_price: 4700 },
      { days: [6, 7], daily_price: 4200 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3800 },
      { days: [15], daily_price: 3500 },
    ],
    price_configs_high: [
      { days: [1, 2], daily_price: 5200 },
      { days: [3, 4, 5], daily_price: 4700 },
      { days: [6, 7], daily_price: 4200 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 3800 },
      { days: [15], daily_price: 3500 },
    ],
  },
  {
    name: 'M2',
    description: 'Veicoli commerciali 9 posti',
    img_url: '../../assets/imgs/vehicle-categories/irent-category-M.jpeg',
    price_configs: [
      { days: [1, 2], daily_price: 11250 },
      { days: [3, 4, 5], daily_price: 8750 },
      { days: [6, 7], daily_price: 8125 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 7500 },
      { days: [15], daily_price: 7500 },
    ],
    price_configs_low: [
      { days: [1, 2], daily_price: 9000 },
      { days: [3, 4, 5], daily_price: 7000 },
      { days: [6, 7], daily_price: 6500 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 6000 },
      { days: [15], daily_price: 6000 },
    ],
    price_configs_high: [
      { days: [1, 2], daily_price: 13500 },
      { days: [3, 4, 5], daily_price: 10500 },
      { days: [6, 7], daily_price: 9750 },
      { days: [8, 9, 10, 11, 12, 13, 14], daily_price: 9000 },
      { days: [15], daily_price: 9000 },
    ],
  },
];

/** DESTINATIONS **/
export const DESTINATIONS: Destination[] = [
  {
    id: 1,
    img_url: '../../../assets/imgs/destinations/irent-destinations-1.jpg',
    title: 'Agrigento',
    description: '200km da C.so Umberto I - Siracusa',
    link: '',
  },
  {
    id: 2,
    img_url: '../../../assets/imgs/destinations/irent-destinations-2.jpg',
    title: 'Catania',
    description: '66km da C.so Umberto I - Siracusa',
    link: '',
  },
  {
    id: 3,
    img_url: '../../../assets/imgs/destinations/irent-destinations-3.jpg',
    title: 'Etna',
    description: '100km da C.so Umberto I - Siracusa',
    link: '',
  },
  {
    id: 4,
    img_url: '../../../assets/imgs/destinations/irent-destinations-4.jpg',
    title: 'Marzamemi',
    description: '53km da C.so Umberto I - Siracusa',
    link: '',
  },
  {
    id: 5,
    img_url: '../../../assets/imgs/destinations/irent-destinations-5.jpg',
    title: 'Modica',
    description: '70km da C.so Umberto I - Siracusa',
    link: '',
  },
  {
    id: 6,
    img_url: '../../../assets/imgs/destinations/irent-destinations-6.jpg',
    title: 'Noto',
    description: '37km da C.so Umberto I - Siracusa',
    link: '',
  },
  {
    id: 7,
    img_url: '../../../assets/imgs/destinations/irent-destinations-7.jpg',
    title: 'Ragusa',
    description: '77km da C.so Umberto I - Siracusa',
    link: '',
  },
  {
    id: 8,
    img_url: '../../../assets/imgs/destinations/irent-destinations-8.jpg',
    title: 'Taormina - Isola bella',
    description: '120km da C.so Umberto I - Siracusa',
    link: '',
  },
];

/** SERVICES **/
export const SERVICES: Service[] = [
  {
    id: 1,
    img_url: '../../../assets/imgs/services/irent-services-1.jpg',
    title: 'Consegna a domicilio',
    description: 'A partire da € 9,00',
    link: '',
    price: 9,
  },
  {
    id: 2,
    img_url: '../../../assets/imgs/services/irent-services-2.jpg',
    title: 'Igienizzazione',
    description: 'A partire da € 29,00',
    link: '',
    price: 29,
  },
  {
    id: 3,
    img_url: '../../../assets/imgs/services/irent-services-3.jpg',
    title: 'Noleggio fuori orario',
    description: 'A partire da € 9,90',
    link: '',
    price: 29,
  },
  {
    id: 4,
    img_url: '../../../assets/imgs/services/irent-services-4.jpg',
    title: 'Young driver',
    description: '18-21 anni',
    link: '',
    price: 0,
  },
  {
    id: 5,
    img_url: '../../../assets/imgs/services/irent-services-5.jpg',
    title: 'Auto per cerimonie',
    description: '',
    link: '',
    price: 99,
  },
  {
    id: 6,
    img_url: '../../../assets/imgs/services/irent-services-6.jpg',
    title: 'Noleggio con conducente',
    description: '',
    link: '',
    price: 139,
  },
  {
    id: 7,
    img_url: '../../../assets/imgs/services/irent-services-7.jpg',
    title: 'Accessori aggiuntivi',
    description: '',
    link: '',
    price: 10,
  },
];

/** SHARING PACKS **/
export const SHARING_PACKS: string[] = [
  '0.2€ al minuto',
  '33€ per 12h',
  '45€ per 24h',
  '80€ per 48h',
  '110€ per 72h',
  '145€ per 4 giorni',
  '180€ per 5 giorni',
  '215€ per 6 giorni',
  '250€ per 7 giorni',
  '285€ per 8 giorni',
  '320€ per 9 giorni',
  '355€ per 10 giorni',
  '390€ per 11 giorni',
  '425€ per 12 giorni',
  '460€ per 13 giorni',
  '495€ per 14 giorni',
  '530€ per 15 giorni',
  '565€ per 16 giorni',
  '600€ per 17 giorni',
  '635€ per 18 giorni',
  '670€ per 19 giorni',
  '705€ per 20 giorni',
  '740€ per 21 giorni',
  '775€ per 22 giorni',
  '810€ per 23 giorni',
  '845€ per 24 giorni',
  '880€ per 25 giorni',
  '915€ per 26 giorni',
  '950€ per 27 giorni',
  '985€ per 28 giorni',
  '1020€ per 29 giorni',
];
