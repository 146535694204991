import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { from } from 'rxjs';
import { API } from 'aws-amplify';
import { Reservation } from './reservation.service';

export const VEHICLES_API_NAME: string = 'vehicles';
export const VEHICLES_API_ENDPOINT: string =
  environment.API_BASE_URL + '/vehicles';

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  listAvailableVehicles(reservation: Reservation) {
    let apiName: string = VEHICLES_API_NAME;
    let path: string =
      '/' + reservation.start_date + '/' + reservation.end_date;
    let init = { response: true };
    return from(API.get(apiName, path, init));
  }

  findVehicles(on_sale?: boolean) {
    let apiName: string = VEHICLES_API_NAME;
    let path: string = '/';
    let init = {
      response: true,
      queryStringParameters: {
        on_sale: on_sale,
      },
    };
    return from(API.get(apiName, path, init));
  }
}

export class Vehicle {
  plate: string;
  category: string;
  brand: string;
  model: string;
  year: number;
  is_on_sale: boolean;
  price: number;
  img_url: string;
  damages: VehicleDamage[];
  revisions: VehicleRevision[];
  link: string;
  km: number;
}

export class VehicleDamage {
  date: number;
  description: string;
  img_url: string;
}

export class VehicleRevision {
  date: number;
  km: number;
  note: string;
}
