import { Component, HostListener, OnInit } from '@angular/core';
import { ResourcesService, Service } from '../../core/resources.service';
import { CONTACTS_PAGE_PATH, MD_SCREEN_WIDTH } from '../../shared/constants';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../../shared/info-dialog/info-dialog.component';
import { Router } from '@angular/router';
import {
  CONTACT_DIALOG_CTA,
  CONTACT_DIALOG_MESSAGE,
  CONTACT_DIALOG_TITLE,
} from '../../shared/translations';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
})
export class ServicesListComponent implements OnInit {
  services: Service[] = [];

  constructor(
    private dialogService: DialogService,
    private dialog: MatDialog,
    private resourcesService: ResourcesService,
    private router: Router
  ) {
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {
    this.services = this.resourcesService.readServices();
    if (this.services.length % 2 != 0 && window.innerWidth >= MD_SCREEN_WIDTH) {
      this.services = this.services.concat(new Service());
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.services = this.resourcesService.readServices();
    if (this.services.length % 2 != 0 && window.innerWidth >= MD_SCREEN_WIDTH) {
      this.services = this.services.concat(new Service());
    }
  }

  onServiceClick() {
    this.dialogService
      .openDialog(InfoDialogComponent, {
        title: CONTACT_DIALOG_TITLE,
        message: CONTACT_DIALOG_MESSAGE,
        cta: CONTACT_DIALOG_CTA,
      })
      .afterClosed()
      .subscribe((dialogResult) => {
        if (dialogResult && dialogResult.confirm) {
          this.router.navigate([CONTACTS_PAGE_PATH]);
        }
      });
  }
}
