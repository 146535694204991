/** PAGE NAMES **/
export const HOME_PAGE_NAME: string = 'home';
export const DESTINATIONS_PAGE_NAME: string = 'destinazioni';
export const SERVICES_PAGE_NAME: string = 'servizi';
export const CONTACTS_PAGE_NAME: string = 'contatti';
export const COMMERCE_PAGE_NAME: string = 'vendita';
export const RESERVATION_PAGE_NAME: string = 'prenotazioni';
export const PRICING_PAGE_NAME: string = 'prezzi';
export const FAQ_PAGE_NAME: string = 'faq';

/** DIALOGS **/
export const ERROR_DIALOG_TITLE: string = 'Errore';
export const ERROR_DIALOG_CTA: string = 'Chiudi';
export const CONTACT_DIALOG_TITLE: string = 'Contattaci';
export const CONTACT_DIALOG_MESSAGE: string =
  'Per saperne di più contattaci: siamo disponibili 24h al giorno!';
export const CONTACT_DIALOG_CTA: string = 'Contattaci subito';
export const COMING_SOON_DIALOG_TITLE: string = 'Coming soon...';
export const COMING_SOON_DIALOG_MESSAGE: string =
  'La prenotazione dei veicoli dal sito non è ancora disponibile.';
export const COMING_SOON_DIALOG_CTA: string = 'Contattaci direttamente';
export const EMAIL_DIALOG_TITLE: string = 'Email inviata';
export const EMAIL_DIALOG_MESSAGE: string =
  'Grazie per averci contattato! Ti risponderemo non appena possibile.';
export const RESERVATION_CREATION_DIALOG_TITLE: string =
  'Prenotazione confermata';
export const RESERVATION_CREATION_DIALOG_MESSAGE: string =
  'La tua prenotazione è stata confermata con successo! Riceverai via mail tutti i dettagli.';
export const SIGNED_CONTRACT_DIALOG_TITLE: string = 'Contratto firmato';
export const SIGNED_CONTRACT_DIALOG_MESSAGE: string =
  'Il contratto per la tua prenotazione è stato firmato con successo.';
export const ERROR_DIALOG_LIVE_CARD_MESSAGE: string =
  'In modalità LIVE non sono accettate carte di test.';
export const ERROR_DIALOG_DECLINED_CARD_MESSAGE: string = 'Carta non valida.';
export const ERROR_TEST_CHARGE_MESSAGE: string =
  'Merchant non attivo: il proprietario dello shop non ha ancora attivato la sua utenza splittypay.';
export const ERROR_DATES_MESSAGE: string =
  'Le date inserite non sono ammesse: accettiamo prenotazioni con un minimo di 24 ore di preavviso e dalla durata minima di 1 ora (la data di inizio non può precedere quella di fine)';
export const ADDRESS_INPUT_ERROR_MESSAGE: string =
  'Indirizzo errato: completare il campo scegliendo uno degli indirizzi dalla lista proposta';

/** ADDRESSES **/
export const HEADQUARTER_ADDRESS: string = 'Siracusa (SEDE C.SO UMBERTO I, 4)';
export const STATION_ADDRESS: string = 'Siracusa (STAZIONE FERROVIARIA)';
export const AIRPORT_ADDRESS: string = 'Catania (AEROPORTO DI CATANIA)';

/** FAQS **/
export const FAQS: { question: string; answer: string }[] = [
  {
    question: "Cos'è IRent?",
    answer:
      'I-Rent è una società giovane e dinamica che fornisce servizi di noleggio e car sharing nella zona di Siracusa.',
  },
  {
    question: 'Dove è attivo il servizio?',
    answer:
      'Le autovetture in sharing sono ritirabili nelle zone circoscritte di Siracusa ed utilizzabili ovunque.',
  },
  {
    question: 'Come faccio ad iscrivermi?',
    answer:
      "Puoi iscriverti scaricando l'app di IRent per Android e per iOS e creando il tuo profilo.\n L'iscrizione è semplice e rapida. Ti verrà richiesto di inserire alcuni dati personali e di scattare una foto al documento d'identità e alla patente di guida.\n" +
      'I tuoi documenti verranno sottoposti a un nostro controllo per verificarne la validità, dopodichè il tuo profilo risulterà attivo e potrai noleggiare i veicoli di IRent.',
  },
  {
    question: "Quanto tempo è necessario per l'approvazione dei documenti?",
    answer:
      "In genere la procedura richiede al massimo un giorno lavorativo. Se hai esigenza di partire nell'immediato puoi contattare il Servizio Clienti.",
  },
  {
    question: 'Come posso contattarvi?',
    answer:
      'Consulta la pagina dei contatti per scriverci o chiamarci al numero indicato.',
  },
  {
    question: 'Quanto costa il noleggio?',
    answer:
      'Il noleggio di una Fiat Panda costa 0.2€ al minuto. Consulta la pagina dei prezzi per avere informazioni dettagliate.',
  },
  {
    question: 'In che orari è attivo il servizio?',
    answer: 'Il nostro servizio è attivo h24, 7 giorni su 7.',
  },
  {
    question: 'Come avviene il pagamento?',
    answer:
      "Il pagamento è eseguito tramite carta di credito o carta prepagata. I dati della carta che preferisci utilizzare per il pagamento ti verranno richiesti al momento della registrazione sull'APP IRent. Puoi modificare questi dati in qualsiasi momento nella sezione Il mio profilo dell'app.",
  },
  {
    question: 'Le auto IRent sono assicurate?',
    answer:
      'Sì. Tutte le auto IRent sono coperte da una polizza assicurativa di responsabilità civile e la tua franchigia è sempre automaticamente ridotta ad un importo specifico in caso di incidente.',
  },
  {
    question: 'Quanto tempo ho per raggiungere un veicolo?',
    answer:
      'Dopo aver prenotato un veicolo, avrai 15 minuti per raggiungerlo, dopodichè la prenotazione sarà cancellata.\n' +
      "E' possibile effettuare una nuova prenotazione sullo stesso veicolo.",
  },
  {
    question: 'Posso durante il noleggio uscire dalla città?',
    answer:
      'Sì, è possibile. L’importante è che, al termine della procedura, il veicolo venga parcheggiato nelle aree adibite al parcheggio all’interno della città e opportunamente segnalate nella mappa presente all’interno dell’app.\n' +
      'Non è possibile varcare i confini nazionali.',
  },
  {
    question:
      'Posso durante il noleggio parcheggiare la macchina e avere la certezza di poterla riutilizzare quando ho sbrigato il mio impegno?',
    answer:
      'Sì, è possibile. Basterà semplicemente cliccare su PAUSA quando si desidera parcheggiare. La macchina risulterà ancora in uso e non sarà prenotabile da nessun altro cliente.',
  },
  {
    question: 'Cosa faccio in caso di incidente?',
    answer:
      'In caso di incidente o danni gravi al veicolo contatta subito il nostro servizio clienti al numero +39 0931 1850575. Un nostro operatore sarà pronto a fornirti la necessaria assistenza.',
  },
  {
    question: 'Cosa faccio se non si apre il veicolo?',
    answer:
      "Controlla prima di tutto che la targa del veicolo corrisponda con quella dei veicolo che hai prenotato sull'App. Se riscontri ancora problemi contatta il servizio clienti al numero +39 0931 1850575",
  },
];

/** CONTRACT **/
export const CONTRACT_DAMAGES_IN_TITLE: string =
  "Danni segnalati sul veicolo all'uscita";
export const CONTRACT_DAMAGES_OUT_TITLE: string =
  'Danni segnalati sul veicolo al rientro';
