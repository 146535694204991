export const environment = {
  production: false,
  API_BASE_URL: 'https://api.dev.irent-autonoleggio.com',
  STRIPE_PUBLIC_KEY:
    'pk_test_51IGgDWBWPX6FJ5IjzSTrgAp3VKB5rdQOEznXzs3c4Kexs9y5FbOjXGd9D7GCLPRlnncD6duayozBzqQ4nuiIypnc00aqdn0TpU',
  USER_POOL_REGION: 'us-east-1',
  USER_POOL_ID: 'us-east-1_U5mvBQcuI',
  USER_POOL_CLIENT_ID: '5hvrckj9usr5b0tmt3r4mojcva',
  IDENTITY_POOL_ID: 'us-east-1:9f27a3b0-d2c9-4d3e-8a05-37fc00ce5cdf',
  IDENTITY_POOL_REGION: 'us-east-1',
  BUCKET_NAME: 'irent-users-storage-dev',
  BUCKET_REGION: 'us-east-1',
};
