<app-toolbar [currentItem]="currentItem"></app-toolbar>

<section
  id="irt-destinations-root-layout"
  class="irt-full-width">
  <app-destinations-hero></app-destinations-hero>
  <app-destinations-list></app-destinations-list>
</section>

<app-footer></app-footer>
