/** Modules **/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinationsRoutingModule } from './destinations-routing.module';
import { SharedModule } from '../shared/shared.module';

/** Material **/
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

/** Components **/
import { DestinationsComponent } from './destinations.component';
import { DestinationsHeroComponent } from './destinations-hero/destinations-hero.component';
import { DestinationsListComponent } from './destinations-list/destinations-list.component';

@NgModule({
  declarations: [
    DestinationsComponent,
    DestinationsHeroComponent,
    DestinationsListComponent,
  ],
  imports: [
    CommonModule,
    DestinationsRoutingModule,
    SharedModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class DestinationsModule {}
