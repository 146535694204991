<div id="irt-contacts-hero-root-layout">
  <h1
    style="margin: -48px 24px 0 24px"
    class="irt-centered-text irt-primary-color irt-title">
    Corso Umberto I, 4, Siracusa, Italy <br />
    +39 0931 1850575
  </h1>

  <button
    class="irt-hero-fab-button"
    mat-fab
    (click)="onScrollButtonClick()">
    <mat-icon> keyboard_arrow_down </mat-icon>
  </button>
</div>
