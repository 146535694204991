<app-toolbar [currentItem]="currentItem"></app-toolbar>

<section
  id="irt-contacts-root-layout"
  class="irt-full-width">
  <app-contacts-hero></app-contacts-hero>
  <div id="irt-contacts-content-layout">
    <app-contacts-form
      id="irt-contacts-content-left-layout"></app-contacts-form>
    <app-contacts-info
      id="irt-contacts-content-right-layout"></app-contacts-info>
  </div>
</section>

<app-footer></app-footer>
