<div id="irt-home-social-root-layout">
  <h3 class="irt-centered-text">PROVA LA NOSTRA APP</h3>
  <div id="irt-home-social-app-layout">
    <button
      mat-button
      class="irt-secondary-button irt-home-social-button irt-margin-left irt-margin-right"
      (click)="onIosAppClick()">
      <img
        class="irt-home-social-button-img irt-half-margin-bottom irt-half-margin-top irt-margin-left"
        src="../../../assets/imgs/home/irent-apple-logo.png" />
      <span class="irt-half-margin-left irt-margin-right"> App store </span>
    </button>

    <button
      mat-button
      class="irt-secondary-button irt-home-social-button irt-margin-left irt-margin-right"
      (click)="onAndroidAppClick()">
      <img
        class="irt-home-social-button-img irt-half-margin-bottom irt-half-margin-top irt-margin-left"
        src="../../../assets/imgs/home/irent-google-play-logo.png" />
      <span class="irt-half-margin-left irt-margin-right"> Google play </span>
    </button>
  </div>

  <h3 class="irt-centered-text irt-double-margin-top">SEGUICI SU</h3>
  <div id="irt-home-social-actions-layout">
    <img
      class="irt-home-social-img irt-margin-left irt-margin-right irt-cursor-pointer"
      src="../../../assets/imgs/home/irent-facebook-logo.png"
      (click)="onSocialLinkClick(facebook_link)" />
    <img
      class="irt-home-social-img irt-margin-left irt-margin-right irt-cursor-pointer"
      src="../../../assets/imgs/home/irent-instagram-logo.png"
      (click)="onSocialLinkClick(instagram_link)" />
  </div>
</div>
