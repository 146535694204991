<div
  class="irt-full-width irt-centered-row irt-double-margin-top"
  style="position: absolute; left: 0; top: calc(50% - 36px)"
  *ngIf="!reservation">
  <div
    class="irt-padding irt-border-radius"
    style="background: #f4f4f4">
    <mat-spinner
      [diameter]="30"
      color="accent">
    </mat-spinner>
  </div>
</div>

<div
  *ngIf="reservation"
  style="padding-bottom: 200px"
  class="irt-full-width irt-row irt-double-padding-md">
  <div
    class="irt-contract-layout irt-border-radius irt-double-padding-md"
    style="margin: auto">
    <app-contract-header></app-contract-header>

    <h3 class="irt-double-margin-top">
      CONTRATTO DI NOLEGGIO VEICOLO SENZA CONDUCENTE N.ro 000596/A
    </h3>
    <div
      class="irt-spaced-row irt-flex-wrap"
      style="align-items: flex-start">
      <div class="irt-contract-content-layout">
        <app-contract-general
          *ngIf="reservation"
          [reservation]="reservation"></app-contract-general>
      </div>

      <div class="irt-contract-content-layout">
        <app-contract-owner
          *ngIf="customer"
          [customer]="customer"></app-contract-owner>
      </div>

      <div
        class="irt-contract-content-layout"
        style="width: 100% !important">
        <app-contract-drivers
          *ngIf="customer"
          [customer]="customer"></app-contract-drivers>
      </div>

      <div class="irt-contract-content-layout">
        <app-contract-vehicle
          *ngIf="vehicle"
          [vehicle]="vehicle"></app-contract-vehicle>
      </div>

      <div class="irt-contract-content-layout">
        <app-contract-license
          *ngIf="customer"
          [customer]="customer"></app-contract-license>
      </div>

      <div class="irt-contract-content-layout">
        <app-contract-services
          *ngIf="reservation"
          [reservation]="reservation"></app-contract-services>
      </div>

      <div class="irt-contract-content-layout">
        <app-contract-notes
          *ngIf="reservation"
          [reservation]="reservation"></app-contract-notes>
      </div>

      <div class="irt-contract-content-layout">
        <app-contract-damages
          *ngIf="vehicle"
          [vehicle]="vehicle"></app-contract-damages>
      </div>

      <div class="irt-contract-content-layout">
        <app-contract-damages
          *ngIf="vehicle"
          [phase]="'out'"
          [vehicle]="vehicle"></app-contract-damages>
      </div>

      <div class="irt-contract-content-layout">
        <app-contract-amount
          *ngIf="amount"
          [amount]="amount"></app-contract-amount>
      </div>

      <span
        class="irt-half-margin-top"
        style="font-size: 10px">
        Accetto la Vs offerta di noleggio e approvo specificatamente ai sensi
        dell’articolo 1341 del C.C. italiano le Condizioni Generali di Noleggio
        I-RENT S.R.L. delle quali mi avete consegnato una copia e che ho
        attentamente esaminato. Dichiaro di conoscere diritti e obblighi
        specificati nel contratto di noleggio, da me letto e firmato,
        conseguenti alle disposizioni 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
        14, 15, 16, 17, 18, 19, 20, 21, 22 dello stesso contratto, che fa parte
        integrante e sostanziale del presente. Dichiaro di aver ricevuto
        completa informativa ai sensi dell’art.13 del D.Lgs. n. 196/2003, ed
        esprimo il mio consenso al trattamento e alla comunicazione dei miei
        dati personali, nei limiti, per finalità anche di natua promozionale, e
        per la durata precisata nell’informativa.
      </span>
    </div>

    <div
      class="irt-full-width"
      style="margin-top: 96px">
      <app-contract-conditions *ngIf="reservation"></app-contract-conditions>
    </div>
  </div>

  <div
    class="irt-full-width irt-padding irt-centered-column irt-primary-medium-background"
    style="position: fixed; bottom: 0; left: 0">
    <h1 class="irt-sign-text irt-no-margin">
      {{ customer.first_name }} {{ customer.last_name }}
    </h1>
    <button
      class="irt-main-button irt-half-margin-top"
      mat-button
      [disabled]="showButtonSpinner"
      [ngClass]="getDisabledClass()"
      (click)="onSignClick()">
      <h3
        class="irt-no-margin"
        *ngIf="!showButtonSpinner">
        Firma contratto
      </h3>
      <div
        class="irt-spinner-layout"
        *ngIf="showButtonSpinner">
        <mat-spinner
          [diameter]="20"
          color="accent">
        </mat-spinner>
      </div>
    </button>
  </div>
</div>
