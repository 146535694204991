import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-hero',
  templateUrl: './services-hero.component.html',
  styleUrls: ['./services-hero.component.scss'],
})
export class ServicesHeroComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  onScrollButtonClick() {
    let scrollToPos = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos < window.innerHeight - 20 || pos > window.innerHeight + 20) {
        if (pos > window.innerHeight) pos = pos - 20;
        else pos = pos + 20;
        window.scrollTo(0, pos);
      } else {
        window.clearInterval(scrollToPos);
      }
    }, 1);
  }
}
