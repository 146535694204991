<div>
  <h1 class="irt-centered-text">
    Seleziona la categoria del veicolo tra quelle disponibili
  </h1>
  <div class="irt-centered-row irt-full-width">
    <div class="irt-accent-background irt-half-padding irt-border-radius">
      <p class="irt-primary-color irt-no-margin">
        {{ reservation.start_date | date : 'short' }} -
        {{ reservation.end_date | date : 'short' }}
      </p>
    </div>
  </div>

  <p
    id="irt-reservation-overprice-text"
    class="irt-centered-text irt-no-margin irt-primary-medium-color">
    *Sovrapprezzo per Catania:
    {{ getDefaultAmount() / 100 | number : '1.0-2' : 'it-IT' }}€
  </p>

  <div
    id="irt-reservation-category-list-layout"
    class="irt-full-width"
    *ngIf="categories && categories.length">
    <div
      id="irt-reservation-category-item-layout"
      *ngFor="let category of categories">
      <app-reservation-category-item
        class="irt-full-width irt-full-height"
        [category]="category"
        [reservation]="reservation"
        (category_select_click)="
          onCategoryClick($event)
        "></app-reservation-category-item>
    </div>
  </div>

  <div
    class="irt-full-width irt-centered-row irt-double-margin-top"
    *ngIf="categories && categories.length <= 0">
    <p>Nessun veicolo disponibile per le date selezionate</p>
  </div>

  <div
    class="irt-full-width irt-centered-row irt-double-margin-top"
    style="position: absolute; left: 0; top: calc(50% - 36px)"
    *ngIf="!categories">
    <div
      class="irt-padding irt-border-radius"
      style="background: #f4f4f4">
      <mat-spinner
        [diameter]="30"
        color="accent">
      </mat-spinner>
    </div>
  </div>
</div>
