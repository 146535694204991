import { Component, OnInit } from '@angular/core';
import { RESERVATION_PAGE_NAME } from '../shared/translations';
import { ActivatedRoute, Router } from '@angular/router';
import { Reservation, ReservationService } from '../core/reservation.service';
import {
  AIRPORT_ADDRESS_VALUE,
  EXTRA_DEFAULT_COST,
  RESERVATION_CATEGORY_STEP,
  RESERVATION_PAGE_PATH,
  RESERVATION_PAYMENT_STEP,
  RESERVATION_SERVICES_STEP,
  RESERVATION_USER_STEP,
} from '../shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../core/dialog.service';
import { VehicleService } from '../core/vehicle.service';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss'],
})
export class ReservationComponent implements OnInit {
  current_item: string = RESERVATION_PAGE_NAME;
  logo: string = '../../../assets/imgs/irent-logo-light.png';

  category_step: string = RESERVATION_CATEGORY_STEP;
  services_step: string = RESERVATION_SERVICES_STEP;
  user_step: string = RESERVATION_USER_STEP;
  payment_step: string = RESERVATION_PAYMENT_STEP;
  current_step: string = this.category_step;

  reservation: Reservation;
  show_button_spinner: boolean = false;

  constructor(
    private router: Router,
    private dialog_service: DialogService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private reservation_service: ReservationService,
    private vehicle_service: VehicleService
  ) {
    this.dialog_service.dialog = this.dialog;
    this.route.queryParams.subscribe((params) => {
      if (params['uri']) {
        this.current_step = this.payment_step;
      } else {
        // Create reservation
        this.current_step = this.category_step;
        this.reservation = new Reservation();
        this.reservation.start_date = +params['start_date'];
        this.reservation.end_date = +params['end_date'];
        this.reservation.start_address = params['start'];
        this.reservation.end_address = params['end'];

        // Set default amount
        let default_amount: number;
        default_amount =
          this.reservation.start_address == AIRPORT_ADDRESS_VALUE
            ? EXTRA_DEFAULT_COST
            : 0;
        default_amount +=
          this.reservation.end_address == AIRPORT_ADDRESS_VALUE
            ? EXTRA_DEFAULT_COST
            : 0;
        this.reservation.payment = {
          amount: default_amount,
          payment_intent_client_secret: '',
        };
      }
    });
  }

  ngOnInit(): void {}

  onCategoryClick(reservation: Reservation): void {
    this.reservation = reservation;
    this.current_step = this.user_step;
  }

  onServiceClick(reservation: Reservation): void {
    this.reservation = reservation;
    this.current_step = this.user_step;
  }

  onUserClick(result: { save: boolean; reservation: Reservation }): void {
    this.reservation = result.reservation;
    this.createReservation(result.save);
  }

  createReservation(save: boolean): void {
    this.show_button_spinner = true;
    this.reservation_service
      .createReservation(this.reservation, save)
      .subscribe({
        next: (response) => {
          this.listAvailableVehicles(response.data);
        },
        error: (error) => {
          console.error(error);
          this.show_button_spinner = false;
        },
      });
  }

  listAvailableVehicles(reservation): void {
    this.vehicle_service.listAvailableVehicles(this.reservation).subscribe({
      next: (response) => {
        const available_vehicles_in_category: any[] = response.data.filter(
          (r) => {
            return r.content.category == reservation.content.vehicle_category;
          }
        );
        let vehicle_to_be_assigned: any;
        if (
          available_vehicles_in_category &&
          available_vehicles_in_category.length > 0
        ) {
          vehicle_to_be_assigned = available_vehicles_in_category[0];
          this.assignRandomVehicle(reservation, vehicle_to_be_assigned);
        } else {
          this.router.navigate([RESERVATION_PAGE_PATH], {
            queryParams: { uri: reservation.key.uri },
          });
          this.show_button_spinner = false;
        }
      },
      error: (error) => {
        this.show_button_spinner = false;
      },
    });
  }

  assignRandomVehicle(reservation, vehicle_to_be_assigned): void {
    this.reservation_service
      .assignVehicle(reservation, vehicle_to_be_assigned)
      .subscribe({
        next: (response) => {
          this.router.navigate([RESERVATION_PAGE_PATH], {
            queryParams: { uri: reservation.key.uri },
          });
          this.show_button_spinner = false;
        },
        error: (error) => {
          this.show_button_spinner = false;
        },
      });
  }
}
