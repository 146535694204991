/** Modules **/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/** Material **/
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

/** Components **/
import { FooterComponent } from './footer/footer.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { CarouselComponent } from './carousel/carousel.component';

@NgModule({
  declarations: [
    FooterComponent,
    ToolbarComponent,
    InfoDialogComponent,
    CarouselComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  entryComponents: [InfoDialogComponent],
  exports: [
    FooterComponent,
    ToolbarComponent,
    InfoDialogComponent,
    CarouselComponent,
  ],
})
export class SharedModule {}
