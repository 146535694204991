<div
  class="irt-primary-medium-background irt-border-radius irt-half-padding"
  style="position: relative">
  <h6
    class="irt-no-margin"
    style="position: absolute; top: -12px; left: 0">
    <u> 10.Importo fatturato </u>
  </h6>

  <div class="irt-full-width irt-spaced-row">
    <h6 class="irt-no-margin irt-primary-medium-color">NETTO</h6>
    <h6 class="irt-no-margin">{{ amount.net / 100 | number : '1.2-2' }}€</h6>
  </div>

  <div class="irt-full-width irt-spaced-row irt-half-margin-top">
    <h6 class="irt-no-margin irt-primary-medium-color">SERVIZI AGGIUNTIVI</h6>
    <h6 class="irt-no-margin">
      {{ amount.service / 100 | number : '1.2-2' }}€
    </h6>
  </div>

  <div class="irt-full-width irt-spaced-row irt-half-margin-top">
    <h6 class="irt-no-margin irt-primary-medium-color">VAT / IVA</h6>
    <h6 class="irt-no-margin">{{ amount.vat / 100 | number : '1.2-2' }}€</h6>
  </div>

  <div class="irt-full-width irt-spaced-row irt-half-margin-top">
    <h6 class="irt-no-margin irt-primary-medium-color">TOTALE</h6>
    <h6 class="irt-no-margin">{{ amount.total / 100 | number : '1.2-2' }}€</h6>
  </div>
</div>
