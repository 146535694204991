import { Component, OnInit } from '@angular/core';
import { PRICING_PAGE_NAME } from '../shared/translations';
import { SHARING_PACKS } from '../core/resources.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  packs: string[] = SHARING_PACKS;
  currentItem: string = PRICING_PAGE_NAME;
  logo = '../../../assets/imgs/irent-logo-light.png';

  constructor() {}

  ngOnInit() {}
}
