<div id="irt-home-advantages-root-layout">
  <div
    id="irt-home-advantages-list-layout"
    class="irt-double-margin-top">
    <div
      id="irt-home-advantages-list-item-layout"
      *ngFor="let advantage of advantages">
      <div id="irt-home-advantages-list-item-circle">
        <img
          class="irt-home-advantages-list-item-icon"
          [src]="advantage.icon" />
      </div>
      <h3
        class="irt-centered-text irt-no-margin irt-margin-top"
        [innerHTML]="advantage.title"></h3>
    </div>
  </div>

  <div id="irt-home-advantages-actions-layout">
    <button
      class="irt-secondary-button irt-home-advantages-button"
      mat-button
      (click)="onDiscoverClick()">
      <h3 class="irt-no-margin">Scopri di più</h3>
    </button>
    <button
      class="irt-main-button irt-home-advantages-button"
      mat-button
      (click)="onReserveClick()">
      <h3 class="irt-no-margin">Prenota un veicolo</h3>
    </button>
  </div>
</div>
