<div
  class="irt-primary-medium-background irt-border-radius irt-half-padding"
  style="position: relative">
  <h6
    class="irt-no-margin"
    style="position: absolute; top: -12px; left: 0">
    <u> 01.Generalità </u>
  </h6>

  <div class="irt-full-width irt-spaced-row">
    <h6 class="irt-no-margin irt-primary-medium-color">DATA RITIRO AUTO</h6>
    <h6 class="irt-no-margin">{{ reservation.start_date | date : 'short' }}</h6>
  </div>

  <div class="irt-full-width irt-spaced-row irt-half-margin-top">
    <h6 class="irt-no-margin irt-primary-medium-color">LUOGO AUTO</h6>
    <h6 class="irt-no-margin">{{ reservation.start_address }}</h6>
  </div>

  <div class="irt-full-width irt-spaced-row irt-half-margin-top">
    <h6 class="irt-no-margin irt-primary-medium-color">DATA RIENTRO AUTO</h6>
    <h6 class="irt-no-margin">{{ reservation.end_date | date : 'short' }}</h6>
  </div>

  <div class="irt-full-width irt-spaced-row irt-half-margin-top">
    <h6 class="irt-no-margin irt-primary-medium-color">LUOGO RIENTRO AUTO</h6>
    <h6 class="irt-no-margin">{{ reservation.end_address }}</h6>
  </div>
</div>
