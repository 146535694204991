import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormService } from '../../core/form.service';
import { EmailService } from '../../core/email.service';
import { InfoDialogComponent } from '../../shared/info-dialog/info-dialog.component';
import {
  EMAIL_DIALOG_MESSAGE,
  EMAIL_DIALOG_TITLE,
} from '../../shared/translations';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-contacts-form',
  templateUrl: './contacts-form.component.html',
  styleUrls: ['./contacts-form.component.scss'],
})
export class ContactsFormComponent implements OnInit {
  formGroup: FormGroup;
  matcher: ErrorStateMatcher;
  showButtonSpinner: boolean = false;

  constructor(
    private formService: FormService,
    private emailService: EmailService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
    this.formGroup = formService.contacts_from_group;
    this.matcher = formService.matcher;
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {}

  onSendEmailClick() {
    if (this.formService.validateForm(this.formGroup)) {
      let name: string = this.formGroup.get('first_name').value;
      let surname: string = this.formGroup.get('last_name').value;
      let email: string = this.formGroup.get('email').value;
      let message: string = this.formGroup.get('message').value;
      this.showButtonSpinner = true;
      this.emailService.sendEmail(name, surname, email, message).subscribe(
        (response) => {
          this.dialogService.openDialog(InfoDialogComponent, {
            title: EMAIL_DIALOG_TITLE,
            message: EMAIL_DIALOG_MESSAGE,
          });
          this.showButtonSpinner = false;
        },
        (error) => {
          console.error(error);
          this.showButtonSpinner = false;
        }
      );
    }
  }
}
