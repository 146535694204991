import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export const EMAIL_URL: string =
  'https://api.hsforms.com/submissions/v3/integration/submit/25077324/85cb59e3-78cd-4ee9-bfd8-03680793ff95';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpClient) {}

  sendEmail(name: string, surname: string, email: string, message: string) {
    const url = EMAIL_URL;
    const body = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          name: 'firstname',
          value: name,
        },
        {
          name: 'lastname',
          value: surname,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'message',
          value: message,
        },
      ],
      context: {
        pageUri: 'https://irent-autonoleggio.com/#/contacts',
        pageName: 'Contacts page',
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: 'I agree to allow IRent to store and process my personal data.',
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: 'I agree to receive marketing communications from IRent.',
            },
          ],
        },
      },
    };
    return this.http.post<any>(url, body);
  }
}
