import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/dialog.service';
import { ADDRESS_INPUT_ERROR_MESSAGE } from 'src/app/shared/translations';
import { FormService } from '../../core/form.service';
import { ReservationService, User } from '../../core/reservation.service';

@Component({
  selector: 'app-reservation-user-business',
  templateUrl: './reservation-user-business.component.html',
  styleUrls: ['./reservation-user-business.component.scss'],
})
export class ReservationUserBusinessComponent {
  @Output() user_submit_click: EventEmitter<{
    formGroup: FormGroup;
    address: any;
  }> = new EventEmitter<{
    formGroup: FormGroup;
    address: any;
  }>();
  @Input() show_button_spinner: boolean;
  form_group: FormGroup;
  is_email_loading: boolean = false;
  address: {
    formattedAddress: string;
    city: string;
    country: string;
    province: string;
    zipCode: string;
  };

  constructor(
    private form_service: FormService,
    private reservation_service: ReservationService,
    private dialog_service: DialogService,
    private dialog: MatDialog
  ) {
    this.form_group = form_service.reservation_business_form_group;
    this.dialog_service.dialog = this.dialog;
  }

  onUserSubmitClick() {
    if (this.form_service.validateForm(this.form_group) && this.address) {
      this.user_submit_click.emit({
        formGroup: this.form_group,
        address: this.address,
      });
    } else if (!this.address) {
      this.dialog_service.openErrorDialog(ADDRESS_INPUT_ERROR_MESSAGE);
    }
  }

  onEmailChange() {
    const email = this.form_group.get('email').value;
    this.is_email_loading = true;
    this.reservation_service.readCustomer(email).subscribe(
      (response) => {
        this.fillUser(response.data.content as User);
        this.is_email_loading = false;
      },
      (error) => {
        this.is_email_loading = false;
      }
    );
  }

  onAddressChange(address) {
    if (address.formatted_address && address.address_components) {
      // Store address
      const storableAddress: string = address.formatted_address
        ? address.formatted_address
        : '';

      // Store city
      const cityComponents = address.address_components.filter((ac) => {
        return ac.types.includes('locality');
      });
      const storableCity: string =
        cityComponents && cityComponents.length > 0
          ? cityComponents[0].long_name
          : '';

      // Store country
      const countryComponents = address.address_components.filter((ac) => {
        return ac.types.includes('country');
      });
      const storableCountry: string =
        countryComponents && countryComponents.length > 0
          ? countryComponents[0].long_name
          : '';

      // Store province
      const provinceComponents = address.address_components.filter((ac) => {
        return ac.types.includes('administrative_area_level_2');
      });
      const storableProvince: string =
        provinceComponents && provinceComponents.length > 0
          ? provinceComponents[0].short_name
          : '';

      // Store postal code
      const zipCodeComponents = address.address_components.filter((ac) => {
        return ac.types.includes('postal_code');
      });
      const storableZipCode: string =
        zipCodeComponents && zipCodeComponents.length > 0
          ? zipCodeComponents[0].short_name
          : '';

      this.address = {
        formattedAddress: storableAddress,
        city: storableCity,
        country: storableCountry,
        province: storableProvince,
        zipCode: storableZipCode,
      };
    } else {
      this.dialog_service.openErrorDialog(ADDRESS_INPUT_ERROR_MESSAGE);
    }
  }

  fillUser(user: User) {
    this.form_group.get('vat').setValue(user.vat);
    this.form_group.get('business_name').setValue(user.business_name);
    this.form_group.get('dest_code').setValue(user.dest_code);
    this.form_group.get('first_name').setValue(user.first_name);
    this.form_group.get('last_name').setValue(user.last_name);
    this.form_group.get('email').setValue(user.email);
    this.form_group.get('phone_prefix').setValue(user.phone.prefix);
    this.form_group.get('phone').setValue(user.phone.value);
    this.form_group.get('code').setValue(user.code);
    this.form_group.get('address').setValue(user.address);
    this.form_group.get('license_num').setValue(user.license_num);
    this.form_group.get('license_date').setValue(user.license_date);
    this.form_group.get('license_exp').setValue(user.license_exp);
    this.form_group.get('license_place').setValue(user.license_place);
    this.address = {
      formattedAddress: user.address,
      city: user.city,
      country: user.country,
      province: user.province,
      zipCode: user.zip_code,
    };
  }

  getDisabledClass() {
    return {
      'irt-main-button': !this.show_button_spinner,
      'irt-main-disabled-button': this.show_button_spinner,
    };
  }
}
