<div
  id="irt-home-hero-root-layout"
  [ngStyle]="{
    'background-image': 'url(' + backgrounds[current_background_index] + ')'
  }">
  <div
    id="irt-home-hero-form-container"
    class="irt-full-height"
    [@enterAnimation]>
    <div
      id="irt-home-hero-form-layout"
      class="irt-full-width irt-border-radius">
      <h1 class="irt-centered-text irt-no-margin">Prenota un veicolo</h1>

      <app-home-hero-form></app-home-hero-form>
    </div>
  </div>

  <button
    id="irt-home-hero-previous-button"
    class="irt-home-hero-disabled-button irt-home-hero-button"
    (click)="onPreviousClick()"
    mat-icon-button
    [disabled]="current_background_index == 0">
    <mat-icon class="irt-primary-color"> keyboard_arrow_left </mat-icon>
  </button>

  <button
    id="irt-home-hero-next-button"
    class="irt-home-hero-disabled-button irt-home-hero-button"
    (click)="onNextClick()"
    mat-icon-button
    [disabled]="current_background_index == backgrounds.length - 1">
    <mat-icon class="irt-primary-color"> keyboard_arrow_right </mat-icon>
  </button>

  <button
    class="irt-hero-fab-button"
    [color]="fab_color"
    mat-fab
    (click)="onScrollButtonClick()">
    <mat-icon> keyboard_arrow_down </mat-icon>
  </button>
</div>
