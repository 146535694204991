import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from '../core/dialog.service';
import { InfoDialogComponent } from '../shared/info-dialog/info-dialog.component';
import {
  HOME_PAGE_NAME,
  SIGNED_CONTRACT_DIALOG_MESSAGE,
  SIGNED_CONTRACT_DIALOG_TITLE,
} from '../shared/translations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  currentItem: string = HOME_PAGE_NAME;
  logo: string = '../../../assets/imgs/irent-logo-accent.png';

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
    this.dialogService.dialog = this.dialog;
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (
        params &&
        params['contract_dialog'] &&
        params['contract_dialog'] == 'show'
      ) {
        this.dialogService.openDialog(InfoDialogComponent, {
          title: SIGNED_CONTRACT_DIALOG_TITLE,
          message: SIGNED_CONTRACT_DIALOG_MESSAGE,
        });
      }
    });
  }
}
