<app-toolbar [currentItem]="currentItem"></app-toolbar>

<section
  id="irt-services-root-layout"
  class="irt-full-width">
  <app-services-hero></app-services-hero>
  <app-services-list></app-services-list>
</section>

<app-footer></app-footer>
