<div
  class="irt-primary-medium-background irt-border-radius irt-half-padding"
  style="position: relative; min-height: 150px">
  <h6
    class="irt-no-margin"
    style="position: absolute; top: -12px; left: 0">
    <u> 07.Note </u>
  </h6>

  <div
    class="irt-full-width"
    *ngFor="let note of reservation.notes">
    <h6 class="irt-no-margin irt-primary-medium-color">{{ note }}</h6>
  </div>
</div>
