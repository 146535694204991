/** Modules **/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Material **/
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

/** Components **/
import { HomeComponent } from './home.component';
import { HomeHeroComponent } from './home-hero/home-hero.component';
import { HomeIntroComponent } from './home-intro/home-intro.component';
import { HomeServicesComponent } from './home-services/home-services.component';
import { HomeAdvantagesComponent } from './home-advantages/home-advantages.component';
import { HomeSocialComponent } from './home-social/home-social.component';
import { HomeHeroFormComponent } from './home-hero-form/home-hero-form.component';

@NgModule({
  declarations: [
    HomeComponent,
    HomeHeroComponent,
    HomeIntroComponent,
    HomeServicesComponent,
    HomeAdvantagesComponent,
    HomeSocialComponent,
    HomeHeroFormComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [HomeSocialComponent],
})
export class HomeModule {}
