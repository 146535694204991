import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CONTACTS_PAGE_PATH } from '../shared/constants';
import { ContactsComponent } from './contacts.component';

const routes: Routes = [
  {
    path: CONTACTS_PAGE_PATH,
    component: ContactsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContactsRoutingModule {}
