import { Component, OnInit } from '@angular/core';
import {
  COMMERCE_PAGE_PATH,
  CONTACTS_PAGE_PATH,
  DESTINATIONS_PAGE_PATH,
  FACEBOOK_LINK,
  FAQ_PAGE_PATH,
  HOME_PAGE_PATH,
  INSTAGRAM_LINK,
  PRICING_PAGE_PATH,
  SERVICES_PAGE_PATH,
} from '../constants';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  termsAndConditionsLink: string =
    'https://irent-users-storage-prod.s3.eu-west-1.amazonaws.com/Termini%2Bgenerali.pdf';
  additionalCoverageLink: string =
    'https://irent-users-storage-prod.s3.eu-west-1.amazonaws.com/Copertura%2Baggiuntiva.pdf';
  sharingConditionsLink: string =
    'https://irent-users-storage-prod.s3.eu-west-1.amazonaws.com/sharing-conditions.pdf';
  sharingGuideLink: string =
    "https://irent-users-storage-prod.s3.eu-west-1.amazonaws.com/APP+-+GUIDA+ALL'UTILIZZO.pdf";
  facebookLink: string = FACEBOOK_LINK;
  instagramLink: string = INSTAGRAM_LINK;
  homePath: string = HOME_PAGE_PATH;
  commercePath: string = COMMERCE_PAGE_PATH;
  destinationsPath: string = DESTINATIONS_PAGE_PATH;
  servicesPath: string = SERVICES_PAGE_PATH;
  contactsPath: string = CONTACTS_PAGE_PATH;
  pricingPath: string = PRICING_PAGE_PATH;
  faqPath: string = FAQ_PAGE_PATH;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {}

  onFooterPageClick(path: string) {
    this.router.navigate([path]);
  }

  onFooterLinkClick(link: string) {
    window.open(link, '_blank');
  }

  onReserveClick() {
    this.route.url.subscribe((url) => {
      if (url && url[0] && url[0].path) {
        if (url[0].path == HOME_PAGE_PATH) {
          window.scrollTo(0, 0);
        } else {
          this.router.navigate([HOME_PAGE_PATH]);
        }
      } else {
        this.router.navigate([HOME_PAGE_PATH]);
      }
    });
  }
}
