<header>
  <mat-toolbar
    id="irt-toolbar"
    [ngClass]="getToolbarClass()">
    <mat-toolbar-row id="irt-toolbar-row">
      <img
        [ngClass]="getLogoClass()"
        [src]="logo" />

      <div id="irt-toolbar-menu-layout">
        <div
          *ngFor="let item of items"
          (click)="onItemClick(item)">
          <p
            [ngClass]="getItemTextClass(item)"
            class="irt-primary-color irt-no-margin irt-margin-left irt-margin-right irt-toolbar-font-size irt-cursor-pointer">
            {{ item }}
          </p>

          <div
            id="irt-toolbar-menu-item-bar"
            class="irt-full-width"
            [ngClass]="getItemBarClass(item)"></div>
        </div>
      </div>

      <button
        id="irt-toolbar-menu-button"
        [ngClass]="getSortButtonClass()"
        mat-icon-button
        (click)="onFullScreenMenuToggleClick()">
        <mat-icon> sort </mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<div
  id="irt-toolbar-full-screen-menu-layout"
  *ngIf="showFullScreenMenu"
  [@slideInOut]>
  <div id="irt-toolbar-full-screen-menu-header-layout">
    <img
      class="irt-toolbar-logo"
      [src]="lightLogo" />

    <button
      mat-icon-button
      (click)="onFullScreenMenuToggleClick()">
      <mat-icon class="irt-primary-color"> clear </mat-icon>
    </button>
  </div>

  <div
    *ngFor="let item of items"
    (click)="onItemClick(item)"
    class="irt-margin-bottom irt-double-margin-top irt-cursor-pointer"
    style="padding: 0 24px; box-sizing: border-box">
    <p
      class="irt-primary-color irt-no-margin"
      [ngClass]="getItemTextClass(item)">
      {{ item }}
    </p>
  </div>
</div>
