import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CustomerType,
  Reservation,
  User,
} from '../../core/reservation.service';
import { FormGroup } from '@angular/forms';
import {
  AIRPORT_ADDRESS_VALUE,
  EXTRA_DEFAULT_COST,
} from 'src/app/shared/constants';

@Component({
  selector: 'app-reservation-user',
  templateUrl: './reservation-user.component.html',
  styleUrls: ['./reservation-user.component.scss'],
})
export class ReservationUserComponent implements OnInit {
  @Input() reservation: Reservation;
  @Input() show_button_spinner: boolean;
  @Output() user_submit_click: EventEmitter<{
    save: boolean;
    reservation: Reservation;
  }> = new EventEmitter<{ save: boolean; reservation: Reservation }>();
  private_view: CustomerType = CustomerType.PRIVATE;
  business_view: CustomerType = CustomerType.COMPANY;
  current_view: CustomerType = this.private_view;

  constructor() {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

  onUserSubmitClick(user_result: { formGroup: FormGroup; address: any }): void {
    const user: User = new User();
    if (this.current_view == this.business_view) {
      user.vat = user_result.formGroup.get('vat').value;
      user.business_name = user_result.formGroup.get('business_name').value;
      user.dest_code = user_result.formGroup.get('dest_code').value;
    }

    user.first_name = user_result.formGroup.get('first_name').value;
    user.last_name = user_result.formGroup.get('last_name').value;
    user.email = user_result.formGroup.get('email').value;
    user.code = user_result.formGroup.get('code').value;
    user.license_num = user_result.formGroup.get('license_num').value;
    user.license_date = user_result.formGroup.get('license_date').value;
    user.license_exp = user_result.formGroup.get('license_exp').value;
    user.license_place = user_result.formGroup.get('license_place').value;
    user.address = user_result.address.formattedAddress;
    user.city = user_result.address.city;
    user.country = user_result.address.country;
    user.province = user_result.address.province;
    user.zip_code = user_result.address.zipCode;
    user.type = this.current_view;

    const phone_prefix = user_result.formGroup.get('phone_prefix').value;
    const phone_value = user_result.formGroup.get('phone').value;
    user.phone = {
      prefix: phone_prefix,
      value: phone_value,
    };

    this.reservation.customer = user;
    this.user_submit_click.emit({
      save: user_result.formGroup.get('save').value,
      reservation: this.reservation,
    });
  }

  getSelectedViewStyle(view: string): { [key: string]: boolean } {
    return {
      'irt-reservation-user-selected-view': view == this.current_view,
      'irt-reservation-user-unselected-view': view != this.current_view,
    };
  }

  getDefaultAmount(): number {
    let default_amount: number;
    default_amount =
      this.reservation.start_address == AIRPORT_ADDRESS_VALUE
        ? EXTRA_DEFAULT_COST
        : 0;
    default_amount +=
      this.reservation.end_address == AIRPORT_ADDRESS_VALUE
        ? EXTRA_DEFAULT_COST
        : 0;
    return default_amount;
  }
}
