<div
  id="irt-destinations-list-root-layout"
  class="irt-full-width">
  <div
    id="irt-destinations-list-layout"
    class="irt-full-width">
    <div
      id="irt-destinations-list-item-layout"
      *ngFor="let destination of destinations">
      <div
        id="irt-destinations-list-item-bar"
        class="irt-full-width"></div>

      <div
        id="irt-destinations-list-item-card"
        class="irt-border-radius"
        *ngIf="destination.title && destination.title != ''">
        <div
          id="irt-destinations-list-item-card-img-layout"
          [ngStyle]="{
            'background-image': 'url(' + destination.img_url + ')'
          }"></div>
        <div id="irt-destinations-list-item-card-content-layout">
          <div class="irt-full-width">
            <h3 class="irt-no-margin">{{ destination.title }}</h3>
            <p
              class="irt-no-margin irt-primary-medium-color irt-ellipsized-text">
              {{ destination.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
