/** Modules **/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingRoutingModule } from './pricing-routing.module';
import { SharedModule } from '../shared/shared.module';
import { HomeModule } from '../home/home.module';

/** Material **/
import { MatButtonModule } from '@angular/material/button';

/** Components **/
import { PricingComponent } from './pricing.component';

@NgModule({
  declarations: [PricingComponent],
  imports: [
    CommonModule,
    PricingRoutingModule,
    MatButtonModule,
    SharedModule,
    HomeModule,
  ],
})
export class PricingModule {}
