<div
  class="irt-primary-medium-background irt-border-radius irt-half-padding"
  style="position: relative; min-height: 150px">
  <h6
    class="irt-no-margin"
    style="position: absolute; top: -12px; left: 0">
    <u> 06.Servizi aggiuntivi </u>
  </h6>

  <div
    class="irt-full-width irt-spaced-row"
    *ngFor="let service of reservation.services">
    <h6 class="irt-no-margin irt-primary-medium-color">{{ service.title }}</h6>
    <h6 class="irt-no-margin">{{ service.price / 100 | number : '0.2-2' }}€</h6>
  </div>

  <div
    class="irt-full-width irt-spaced-row"
    *ngFor="let deductible of reservation.deductibles">
    <h6 class="irt-no-margin irt-primary-medium-color">
      {{ deductible.title }}
    </h6>
    <h6 class="irt-no-margin">
      {{ deductible.price / 100 | number : '0.2-2' }}€
    </h6>
  </div>
</div>
