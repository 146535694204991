<div id="irt-services-hero-root-layout">
  <h1
    style="margin: -48px 24px 0 24px"
    class="irt-centered-text irt-primary-color irt-title">
    Scopri e sfrutta tutti i servizi di IRent
  </h1>

  <button
    class="irt-hero-fab-button"
    mat-fab
    (click)="onScrollButtonClick()">
    <mat-icon> keyboard_arrow_down </mat-icon>
  </button>
</div>
