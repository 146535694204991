/** PAGE PATHS **/
export const HOME_PAGE_PATH: string = 'home';
export const DESTINATIONS_PAGE_PATH: string = 'destinations';
export const SERVICES_PAGE_PATH: string = 'services';
export const CONTACTS_PAGE_PATH: string = 'contacts';
export const COMMERCE_PAGE_PATH: string = 'commerce';
export const RESERVATION_PAGE_PATH: string = 'reserve';
export const PRICING_PAGE_PATH: string = 'pricing';
export const FAQ_PAGE_PATH: string = 'faq';
export const CONTRACT_PAGE_PATH: string = 'contract';

/** SCREEN DIMENS **/
export const MD_SCREEN_WIDTH: number = 768;
export const LG_SCREEN_WIDTH: number = 992;

/** SOCIAL LINKS **/
export const FACEBOOK_LINK: string = 'https://www.facebook.com/irent2015';
export const INSTAGRAM_LINK: string = 'https://www.instagram.com/i_rent_srl/';
export const APPLE_APP_LINK: string =
  'https://apps.apple.com/it/app/i-rent/id1558445153';
export const ANDROID_APP_LINK: string =
  'https://play.google.com/store/apps/details?id=irent.autonoleggio.com';

/** RESERVATION STEPS **/
export const RESERVATION_CATEGORY_STEP: string = 'category';
export const RESERVATION_SERVICES_STEP: string = 'services';
export const RESERVATION_USER_STEP: string = 'user';
export const RESERVATION_PAYMENT_STEP: string = 'payment';

/** API ERRORS **/
export const ERROR_404_CODE: string = 'ResourceNotFound';
export const CARD_DECLINED_ERROR: string = 'card_declined';
export const CARD_DECLINED_LIVE_ERROR: string = 'live_mode_test_card';
export const TEST_CHARGES_ERROR: string = 'testmode_charges_only';

/** ADDRESSES **/
export const HEADQUARTER_ADDRESS_VALUE: string = 'siracusa-sede';
export const STATION_ADDRESS_VALUE: string = 'siracusa-stazione';
export const AIRPORT_ADDRESS_VALUE: string = 'catania';

/** UTILS **/
export const IVA: number = 0.22;
export const EXTRA_DEFAULT_COST: number = 4000;

/** VEHICLE STATUS **/
export enum VehicleStatus {
  FREE = 'free',
  SCHEDULED = 'scheduled',
  BUSY = 'busy',
}

/** DATE AND TIME **/
export const HOURS: string[] = [
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
];

/** BACKGROUNDS **/
export const SLIDESHOW_BACKGROUNDS: string[] = [
  '../../../assets/imgs/home/irent-home-background-1.jpg',
  '../../../assets/imgs/home/irent-home-background-2.jpg',
  '../../../assets/imgs/home/irent-home-background-3.jpg',
  '../../../assets/imgs/home/irent-home-background-4.jpg',
];
