import { Component } from '@angular/core';
import { SERVICES_PAGE_NAME } from '../shared/translations';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent {
  currentItem: string = SERVICES_PAGE_NAME;
}
