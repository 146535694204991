<div>
  <h1 class="irt-centered-text">
    Compila i campi sottostanti e prenota il tuo veicolo
  </h1>
  <div class="irt-centered-row irt-full-width irt-flex-wrap">
    <div
      class="irt-accent-background irt-half-padding irt-half-margin irt-border-radius">
      <p class="irt-primary-color irt-no-margin">
        {{ reservation.start_date | date : 'short' }} -
        {{ reservation.end_date | date : 'short' }}
      </p>
    </div>

    <div
      class="irt-accent-background irt-half-padding irt-half-margin irt-border-radius">
      <p class="irt-primary-color irt-no-margin">
        Categoria {{ reservation.vehicle_category }} - Prezzo
        {{ reservation.payment.amount / 100 | number : '1.0-2' : 'it-IT' }}€
      </p>
    </div>
  </div>

  <p
    id="irt-reservation-overprice-text"
    class="irt-centered-text irt-no-margin irt-primary-medium-color">
    *Sovrapprezzo per Catania:
    {{ getDefaultAmount() / 100 | number : '1.0-2' : 'it-IT' }}€
  </p>

  <div class="irt-centered-row irt-half-margin-bottom irt-margin-top">
    <button
      mat-button
      class="irt-half-margin-right"
      (click)="current_view = private_view"
      [ngClass]="getSelectedViewStyle(private_view)">
      <h3 class="irt-no-margin">Privato</h3>
    </button>
    <button
      mat-button
      class="irt-half-margin-left"
      (click)="current_view = business_view"
      [ngClass]="getSelectedViewStyle(business_view)">
      <h3 class="irt-no-margin">Azienda</h3>
    </button>
  </div>

  <app-reservation-user-private
    *ngIf="current_view == private_view"
    [show_button_spinner]="show_button_spinner"
    (user_submit_click)="
      onUserSubmitClick($event)
    "></app-reservation-user-private>
  <app-reservation-user-business
    *ngIf="current_view == business_view"
    [show_button_spinner]="show_button_spinner"
    (user_submit_click)="
      onUserSubmitClick($event)
    "></app-reservation-user-business>
</div>
