import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-amount',
  templateUrl: './contract-amount.component.html',
  styleUrls: ['./contract-amount.component.scss'],
})
export class ContractAmountComponent implements OnInit {
  @Input() amount: { net: number; service: number; vat: number; total: number };

  constructor() {}

  ngOnInit(): void {}
}
