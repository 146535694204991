/** Modules **/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsRoutingModule } from './contacts-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Material **/
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/** Components **/
import { ContactsComponent } from './contacts.component';
import { ContactsHeroComponent } from './contacts-hero/contacts-hero.component';
import { ContactsFormComponent } from './contacts-form/contacts-form.component';
import { ContactsInfoComponent } from './contacts-info/contacts-info.component';

@NgModule({
  declarations: [
    ContactsComponent,
    ContactsHeroComponent,
    ContactsFormComponent,
    ContactsInfoComponent,
  ],
  imports: [
    CommonModule,
    ContactsRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatProgressSpinnerModule,
  ],
})
export class ContactsModule {}
