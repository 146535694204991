<div id="irt-info-dialog-root-layout">
  <div
    class="irt-full-width irt-spaced-row irt-padding"
    style="padding-bottom: 0">
    <h3 class="irt-no-margin irt-primary-medium-color">{{ title }}</h3>
    <button
      mat-icon-button
      (click)="onDialogCloseClick()"
      style="background-color: #f4f4f4">
      <mat-icon> clear </mat-icon>
    </button>
  </div>

  <div
    id="irt-info-dialog-content-layout"
    class="irt-full-width">
    <p class="irt-no-margin">{{ message }}</p>
  </div>

  <div
    id="irt-info-dialog-actions-layout"
    class="irt-full-width irt-centered-row">
    <button
      class="irt-main-button"
      mat-button
      (click)="onDialogConfirmClick()"
      *ngIf="cta">
      <span> {{ cta }} </span>
    </button>
  </div>
</div>
