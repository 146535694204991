import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {
  CUSTOMERS_API_ENDPOINT,
  CUSTOMERS_API_NAME,
  RESERVATIONS_API_ENDPOINT,
  RESERVATIONS_API_NAME,
} from './app/core/reservation.service';
import {
  VEHICLES_API_ENDPOINT,
  VEHICLES_API_NAME,
} from './app/core/vehicle.service';
import { Auth, API, Storage } from 'aws-amplify';

Auth.configure({
  Auth: {
    region: environment.USER_POOL_REGION,
    userPoolId: environment.USER_POOL_ID,
    userPoolWebClientId: environment.USER_POOL_CLIENT_ID,
    identityPoolId: environment.IDENTITY_POOL_ID,
    identityPoolRegion: environment.IDENTITY_POOL_REGION,
  },
});

API.configure({
  endpoints: [
    {
      name: VEHICLES_API_NAME,
      endpoint: VEHICLES_API_ENDPOINT,
      region: environment.USER_POOL_REGION,
    },
    {
      name: RESERVATIONS_API_NAME,
      endpoint: RESERVATIONS_API_ENDPOINT,
      region: environment.USER_POOL_REGION,
    },
    {
      name: CUSTOMERS_API_NAME,
      endpoint: CUSTOMERS_API_ENDPOINT,
      region: environment.USER_POOL_REGION,
    },
  ],
});

Storage.configure({
  AWSS3: {
    bucket: environment.BUCKET_NAME,
    region: environment.BUCKET_REGION,
  },
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
