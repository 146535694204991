import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/core/reservation.service';

@Component({
  selector: 'app-contract-owner',
  templateUrl: './contract-owner.component.html',
  styleUrls: ['./contract-owner.component.scss'],
})
export class ContractOwnerComponent implements OnInit {
  @Input() customer: User;

  constructor() {}

  ngOnInit(): void {}
}
