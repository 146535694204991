import { Component } from '@angular/core';
import { SERVICES_PAGE_PATH } from '../../shared/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-advantages',
  templateUrl: './home-advantages.component.html',
  styleUrls: ['./home-advantages.component.scss'],
})
export class HomeAdvantagesComponent {
  advantages: { title: string; icon: string }[] = [
    {
      title: 'Ampia gamma di <br> vetture e furgoni',
      icon: '../../../assets/imgs/icons/irt-car-icon.png',
    },
    {
      title: 'Prenotazioni in <br> tempo reale',
      icon: '../../../assets/imgs/icons/irt-pc-icon.png',
    },
    {
      title: 'Disponibilità <br> 24 ore su 24',
      icon: '../../../assets/imgs/icons/irt-telephone-icon.png',
    },
  ];

  constructor(private router: Router) {}

  onDiscoverClick() {
    this.router.navigate([SERVICES_PAGE_PATH]);
  }

  onReserveClick() {
    window.scrollTo(0, 0);
  }
}
