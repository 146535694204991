import { Component, Input, OnInit } from '@angular/core';
import { Vehicle } from 'src/app/core/vehicle.service';

@Component({
  selector: 'app-contract-vehicle',
  templateUrl: './contract-vehicle.component.html',
  styleUrls: ['./contract-vehicle.component.scss'],
})
export class ContractVehicleComponent implements OnInit {
  @Input() vehicle: Vehicle;

  constructor() {}

  ngOnInit(): void {}
}
