/** Modules **/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationRoutingModule } from './reservation-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

/** Material **/
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

/** Components **/
import { ReservationComponent } from './reservation.component';
import { ReservationCategoryComponent } from './reservation-category/reservation-category.component';
import { ReservationServicesComponent } from './reservation-services/reservation-services.component';
import { ReservationUserComponent } from './reservation-user/reservation-user.component';
import { ReservationPaymentComponent } from './reservation-payment/reservation-payment.component';
import { MatIconModule } from '@angular/material/icon';
import { ReservationUserPrivateComponent } from './reservation-user-private/reservation-user-private.component';
import { ReservationUserBusinessComponent } from './reservation-user-business/reservation-user-business.component';
import { ReservationCategoryItemComponent } from './reservation-category-item/reservation-category-item.component';

@NgModule({
  declarations: [
    ReservationComponent,
    ReservationCategoryComponent,
    ReservationServicesComponent,
    ReservationUserComponent,
    ReservationPaymentComponent,
    ReservationUserPrivateComponent,
    ReservationUserBusinessComponent,
    ReservationCategoryItemComponent,
  ],
  imports: [
    CommonModule,
    ReservationRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    GooglePlaceModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
})
export class ReservationModule {}
