<app-toolbar
  [currentItem]="current_item"
  [logo]="logo"
  [forceBlackToolbar]="true"></app-toolbar>

<section id="irt-reservation-root-layout">
  <app-reservation-category
    *ngIf="current_step == category_step && reservation"
    [reservation]="reservation"
    (category_select_click)="
      onCategoryClick($event)
    "></app-reservation-category>

  <app-reservation-services
    *ngIf="current_step == services_step && reservation"
    [reservation]="reservation"
    (services_select_click)="onServiceClick($event)"></app-reservation-services>

  <app-reservation-user
    *ngIf="current_step == user_step && reservation"
    [show_button_spinner]="show_button_spinner"
    [reservation]="reservation"
    (user_submit_click)="onUserClick($event)"></app-reservation-user>

  <app-reservation-payment
    *ngIf="current_step == payment_step"></app-reservation-payment>
</section>
