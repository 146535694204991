<form
  class="irt-form irt-reservation-user-form irt-double-padding-md irt-border-radius"
  [formGroup]="form_group">
  <h3 class="irt-primary-medium-color irt-no-margin irt-margin-bottom">
    Inserisci le informazioni del conducente
  </h3>
  <mat-form-field
    appearance="outline"
    class="irt-full-width irt-reservation-builder-basics-field">
    <mat-label> Email </mat-label>
    <input
      matInput
      type="text"
      formControlName="email"
      required
      (change)="onEmailChange()" />
    <mat-spinner
      matSuffix
      color="accent"
      diameter="20"
      *ngIf="is_email_loading"></mat-spinner>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="irt-half-width irt-reservation-builder-basics-field">
    <mat-label> Nome </mat-label>
    <input
      matInput
      type="text"
      formControlName="first_name"
      required />
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="irt-half-width irt-reservation-builder-basics-field">
    <mat-label> Cognome </mat-label>
    <input
      matInput
      type="text"
      formControlName="last_name"
      required />
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="irt-full-width irt-reservation-builder-basics-field">
    <mat-label> Codice fiscale </mat-label>
    <input
      matInput
      type="text"
      formControlName="code"
      required />
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="irt-third-width irt-reservation-builder-basics-field">
    <mat-label> Prefisso </mat-label>
    <mat-icon
      matPrefix
      class="irt-primary-medium-color">
      add
    </mat-icon>
    <input
      matInput
      type="text"
      formControlName="phone_prefix" />
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="irt-two-third-width irt-reservation-builder-basics-field">
    <mat-label> Telefono </mat-label>
    <input
      matInput
      type="text"
      formControlName="phone"
      required />
  </mat-form-field>

  <mat-form-field
    class="irt-full-width"
    appearance="outline"
    color="accent">
    <mat-label> Indirizzo di residenza </mat-label>
    <input
      ngx-google-places-autocomplete
      matInput
      (onAddressChange)="onAddressChange($event)"
      type="text"
      matInput
      formControlName="address"
      required />
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="irt-half-width irt-reservation-builder-basics-field">
    <mat-label> Numero patente </mat-label>
    <input
      matInput
      type="text"
      formControlName="license_num"
      required />
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="irt-half-width irt-reservation-builder-basics-field">
    <mat-label> Luogo emissione patente </mat-label>
    <input
      matInput
      type="text"
      formControlName="license_place"
      required />
  </mat-form-field>

  <mat-form-field
    class="irt-half-width irt-reservation-builder-basics-field"
    appearance="outline">
    <mat-label> Data emissione patente </mat-label>
    <input
      matInput
      type="text"
      required
      formControlName="license_date"
      [matDatepicker]="licenseDatePicker" />
    <mat-datepicker-toggle
      matSuffix
      [for]="licenseDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #licenseDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field
    class="irt-half-width irt-reservation-builder-basics-field"
    appearance="outline">
    <mat-label> Data scadenza patente </mat-label>
    <input
      matInput
      type="text"
      required
      formControlName="license_exp"
      [matDatepicker]="licenseExpDatePicker" />
    <mat-datepicker-toggle
      matSuffix
      [for]="licenseExpDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #licenseExpDatePicker></mat-datepicker>
  </mat-form-field>

  <div
    class="irt-centered-row irt-margin-bottom"
    style="justify-content: flex-start">
    <mat-checkbox
      class="irt-checkbox-layout"
      formControlName="save"></mat-checkbox>
    <h3 class="irt-no-margin irt-half-margin-left irt-primary-medium-color">
      Salvare dati (*)?
    </h3>
  </div>

  <button
    class="irt-main-button irt-full-width"
    mat-button
    [disabled]="show_button_spinner"
    [ngClass]="getDisabledClass()"
    (click)="onUserSubmitClick()">
    <span *ngIf="!show_button_spinner"> Prenota </span>
    <div
      class="irt-spinner-layout"
      *ngIf="show_button_spinner">
      <mat-spinner
        [diameter]="20"
        color="accent">
      </mat-spinner>
    </div>
  </button>

  <p
    class="irt-no-margin irt-centered-text"
    style="font-size: 12px">
    *Salvando i tuoi dati dichiari di aver preso visione e di accettare i
    Termini e le Condizioni che puoi trovare sul nostro sito
  </p>
</form>
