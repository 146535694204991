import { Injectable } from '@angular/core';
import { Service } from './resources.service';
import { environment } from '../../environments/environment';
import { from } from 'rxjs';
import { API } from 'aws-amplify';

export const RESERVATIONS_API_NAME: string = 'reservations';
export const RESERVATIONS_API_ENDPOINT: string =
  environment.API_BASE_URL + '/reservations';
export const CUSTOMERS_API_NAME: string = 'customers';
export const CUSTOMERS_API_ENDPOINT: string =
  environment.API_BASE_URL + '/customers';

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  createReservation(reservation: Reservation, save?: boolean) {
    let apiName: string = RESERVATIONS_API_NAME;
    let path: string = '/';
    let init = {
      response: true,
      body: { content: reservation },
      queryStringParameters: {
        save_customer: save,
      },
    };
    return from(API.post(apiName, path, init));
  }

  readReservation(uri: string) {
    let apiName: string = RESERVATIONS_API_NAME;
    let path: string = '/' + uri;
    let init = { response: true };
    return from(API.get(apiName, path, init));
  }

  readCustomer(email: string) {
    let apiName: string = CUSTOMERS_API_NAME;
    let path: string = '/email/' + email;
    let init = { response: true };
    return from(API.get(apiName, path, init));
  }

  assignVehicle(reservation, vehicle) {
    let apiName: string = RESERVATIONS_API_NAME;
    let path: string =
      '/' + reservation.key.uri + '/vehicles/' + vehicle.key.uri;
    let init = {
      response: true,
      queryStringParameters: {
        send_contract: false,
      },
    };
    return from(API.put(apiName, path, init));
  }

  signContract(token: string, uri: string) {
    let apiName: string = RESERVATIONS_API_NAME;
    let path: string = '/' + uri + '/contract/accept';
    let init = {
      response: true,
      queryStringParameters: {
        token: token,
      },
    };
    return from(API.post(apiName, path, init));
  }
}

export class Reservation {
  customer: User;
  payment: {
    amount: number;
    payment_intent_client_secret: string;
  };
  start_address: string;
  start_date: number;
  end_address: string;
  end_date: number;
  vehicle_category: string;
  services: Service[];
  deductibles: Deductible[];
  notes: string[];
}

export class Deductible {
  title: string;
  price: number;
}

export class User {
  first_name: string;
  last_name: string;
  email: string;
  phone: { prefix: string; value: string };
  code: string;
  address: string;
  zip_code: string;
  city: string;
  province: string;
  country: string;
  vat: string;
  business_name: string;
  dest_code: string;
  type: CustomerType;
  license_num: string;
  license_date: number;
  license_exp: number;
  license_place: string;
}

export enum CustomerType {
  PRIVATE = 'private',
  COMPANY = 'company',
}
