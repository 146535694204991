<div
  id="irt-commerce-list-root-layout"
  class="irt-full-width">
  <div
    id="irt-commerce-list-layout"
    class="irt-full-width"
    *ngIf="vehicles && vehicles.length > 1">
    <div
      id="irt-commerce-list-item-layout"
      *ngFor="let vehicle of vehicles">
      <div class="irt-commerce-list-item-bar irt-full-width"></div>
      <div
        id="irt-commerce-list-item-card"
        class="irt-border-radius"
        *ngIf="vehicle.plate">
        <div
          class="irt-commerce-list-item-card-img-layout"
          [ngStyle]="{
            'background-image': 'url(' + getImageUrl(vehicle) + ')'
          }"></div>
        <div class="irt-commerce-list-item-card-content-layout">
          <div class="irt-full-width">
            <h3 class="irt-no-margin">
              {{ vehicle.brand }} {{ vehicle.model }}
            </h3>
            <p
              class="irt-no-margin irt-primary-medium-color irt-ellipsized-text">
              {{ vehicle.year }} - {{ vehicle.price | number : '' : 'it-IT' }}€
            </p>
          </div>

          <button
            class="irt-commerce-list-item-card-button irt-main-button"
            (click)="onVehicleClick(vehicle)"
            mat-button>
            <span> Scopri di più </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    id="irt-commerce-list-single-item-layout"
    class="irt-full-width"
    *ngIf="vehicles && vehicles.length == 1">
    <div class="irt-commerce-list-item-bar irt-full-width"></div>
    <div
      id="irt-commerce-list-single-item-card"
      class="irt-border-radius">
      <div
        class="irt-commerce-list-item-card-img-layout"
        [ngStyle]="{
          'background-image': 'url(' + getImageUrl(vehicles[0]) + ')'
        }"></div>
      <div class="irt-commerce-list-item-card-content-layout">
        <div class="irt-full-width">
          <h3 class="irt-no-margin">
            {{ vehicles[0].brand }} {{ vehicles[0].model }}
          </h3>
          <p class="irt-no-margin irt-primary-medium-color irt-ellipsized-text">
            {{ vehicles[0].year }} -
            {{ vehicles[0].price | number : '1.0-2' : 'it-IT' }}€
          </p>
        </div>

        <button
          (click)="onVehicleClick(vehicles[0])"
          class="irt-commerce-list-item-card-button irt-main-button"
          mat-button>
          <span> Scopri di più </span>
        </button>
      </div>
    </div>
  </div>

  <div
    class="irt-full-width irt-centered-row irt-double-margin-top irt-padding"
    *ngIf="!vehicles">
    <mat-spinner
      diameter="40"
      color="accent"></mat-spinner>
  </div>

  <div
    *ngIf="vehicles && vehicles.length == 0"
    class="irt-double-margin-top irt-padding">
    <h2 class="irt-centered-text">
      Non ci sono veicoli attualmente in vendita
    </h2>
  </div>
</div>
