import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  Reservation,
  ReservationService,
} from '../../core/reservation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DialogService } from '../../core/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import {
  AIRPORT_ADDRESS_VALUE,
  CARD_DECLINED_ERROR,
  CARD_DECLINED_LIVE_ERROR,
  EXTRA_DEFAULT_COST,
  HOME_PAGE_PATH,
  TEST_CHARGES_ERROR,
} from '../../shared/constants';
import {
  ERROR_DIALOG_DECLINED_CARD_MESSAGE,
  ERROR_DIALOG_LIVE_CARD_MESSAGE,
  ERROR_TEST_CHARGE_MESSAGE,
  RESERVATION_CREATION_DIALOG_MESSAGE,
  RESERVATION_CREATION_DIALOG_TITLE,
} from '../../shared/translations';
import { InfoDialogComponent } from '../../shared/info-dialog/info-dialog.component';

@Component({
  selector: 'app-reservation-payment',
  templateUrl: './reservation-payment.component.html',
  styleUrls: ['./reservation-payment.component.scss'],
})
export class ReservationPaymentComponent implements OnInit, AfterViewInit {
  reservation: Reservation;
  show_button_spinner: boolean = false;

  stripe;
  elements;
  card_element;

  constructor(
    private route: ActivatedRoute,
    private reservation_service: ReservationService,
    private dialog_service: DialogService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.dialog_service.dialog = this.dialog;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.queryParams.subscribe((params) => {
      if (params['uri']) {
        this.readReservation(params['uri']);
      }
    });
  }

  ngAfterViewInit(): void {
    this.setupStripe();
  }

  onConfirmPaymentClick(): void {
    this.show_button_spinner = true;
    let context = this;
    this.stripe
      .confirmCardPayment(
        this.reservation.payment.payment_intent_client_secret,
        {
          payment_method: {
            card: this.card_element,
            billing_details: {
              name:
                this.reservation.customer.first_name +
                ' ' +
                this.reservation.customer.last_name,
            },
          },
        }
      )
      .then(function (result) {
        context.show_button_spinner = false;
        if (result.error) {
          console.error(result.error);
          if (
            result.error.code == CARD_DECLINED_ERROR &&
            result.error.decline_code == CARD_DECLINED_LIVE_ERROR
          ) {
            context.dialog_service.openErrorDialog(
              ERROR_DIALOG_LIVE_CARD_MESSAGE
            );
          } else if (result.error.code == CARD_DECLINED_ERROR) {
            context.dialog_service.openErrorDialog(
              ERROR_DIALOG_DECLINED_CARD_MESSAGE
            );
          } else if (result.error.code == TEST_CHARGES_ERROR) {
            context.dialog_service.openErrorDialog(ERROR_TEST_CHARGE_MESSAGE);
          } else {
            context.dialog_service.openErrorDialog(result.error.message);
          }
        } else {
          context.dialog_service.openDialog(InfoDialogComponent, {
            title: RESERVATION_CREATION_DIALOG_TITLE,
            message: RESERVATION_CREATION_DIALOG_MESSAGE,
          });
          context.router.navigate([HOME_PAGE_PATH]);
        }
      });
  }

  readReservation(uri: string): void {
    this.reservation_service.readReservation(uri).subscribe(
      (response) => {
        this.reservation = response.data.content as Reservation;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  setupStripe(): void {
    // @ts-ignore
    this.stripe = Stripe(environment.STRIPE_PUBLIC_KEY);
    this.elements = this.stripe.elements();
    this.card_element = this.elements.create('card', { hidePostalCode: true });
    this.card_element.mount('#irt-reservation-payment-card');
  }

  isCardVisible(): { [key: string]: boolean | Reservation } {
    return {
      'irt-reservation-payment-hidden-card': !this.reservation,
      '': this.reservation,
    };
  }

  getDefaultAmount(): number {
    let defaultAmount: number;
    defaultAmount =
      this.reservation.start_address == AIRPORT_ADDRESS_VALUE
        ? EXTRA_DEFAULT_COST
        : 0;
    defaultAmount +=
      this.reservation.end_address == AIRPORT_ADDRESS_VALUE
        ? EXTRA_DEFAULT_COST
        : 0;
    return defaultAmount;
  }

  getDisabledClass(): { [key: string]: boolean } {
    return {
      'irt-main-button': !this.show_button_spinner,
      'irt-main-disabled-button': this.show_button_spinner,
    };
  }
}
