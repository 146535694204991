import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/core/reservation.service';

@Component({
  selector: 'app-contract-drivers',
  templateUrl: './contract-drivers.component.html',
  styleUrls: ['./contract-drivers.component.scss'],
})
export class ContractDriversComponent implements OnInit {
  @Input() customer: User;

  constructor() {}

  ngOnInit(): void {}
}
