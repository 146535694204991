<div
  class="irt-spaced-row"
  style="align-items: flex-start">
  <img class="irt-contract-header-logo" />

  <div>
    <h3 class="irt-no-margin irt-right-text">I-Rent srl</h3>
    <h5
      class="irt-no-margin irt-right-text irt-primary-medium-color irt-half-margin-top">
      P.IVA: 01744200708
    </h5>
    <h5
      class="irt-no-margin irt-right-text irt-primary-medium-color irt-half-margin-top">
      Corso Umberto I, 4, Siracusa, Italy
    </h5>
    <h5
      class="irt-no-margin irt-right-text irt-primary-medium-color irt-half-margin-top">
      +39 0931 1850575 - Assistenza stradale: +39 327 9093333 - Whatsapp: +39
      392 1801939
    </h5>
    <h5
      class="irt-no-margin irt-right-text irt-primary-medium-color irt-half-margin-top">
      irent2015@libero.it - https://irent-autonoleggio.com/
    </h5>
  </div>
</div>
