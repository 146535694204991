import { Component, Input, OnInit } from '@angular/core';
import { Vehicle } from 'src/app/core/vehicle.service';
import {
  CONTRACT_DAMAGES_IN_TITLE,
  CONTRACT_DAMAGES_OUT_TITLE,
} from 'src/app/shared/translations';

@Component({
  selector: 'app-contract-damages',
  templateUrl: './contract-damages.component.html',
  styleUrls: ['./contract-damages.component.scss'],
})
export class ContractDamagesComponent implements OnInit {
  @Input() vehicle: Vehicle;
  @Input() phase: string = 'in';

  constructor() {}

  ngOnInit(): void {}

  getDamagesIndex(): string {
    return this.phase == 'in' ? '08' : '09';
  }

  getDamagesTitle(): string {
    return this.phase == 'in'
      ? CONTRACT_DAMAGES_IN_TITLE
      : CONTRACT_DAMAGES_OUT_TITLE;
  }
}
