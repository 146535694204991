<div id="irt-home-intro-root-layout">
  <img
    id="irt-home-intro-logo"
    src="../../../assets/imgs/irent-logo-dark.png" />

  <div id="irt-home-intro-text-layout">
    <h3
      id="irt-home-intro-text"
      class="irt-centered-text">
      I-Rent è una società giovane e dinamica che, grazie all'esperienza
      maturata nel settore
      <span class="irt-accent-color"> noleggio auto e furgoni </span>
      , contribuisce con passione e serietà allo sviluppo turistico della
      Sicilia.
    </h3>
  </div>

  <div id="irt-home-intro-actions-layout">
    <button
      class="irt-secondary-button irt-home-intro-button"
      mat-button
      (click)="onDiscoverClick()">
      <h3 class="irt-no-margin">Scopri di più</h3>
    </button>

    <button
      class="irt-main-button irt-home-intro-button"
      mat-button
      (click)="onReserveClick()">
      <h3 class="irt-no-margin">Prenota un veicolo</h3>
    </button>
  </div>
</div>
